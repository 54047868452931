"use client";

import { useIsServerSideRendering } from "@hopper-b2b/utilities";
import { I18nProvider, iI18nProvider } from "./I18nProvider";
import { I18nProviderSSR } from "./I18nProviderSSR";

export const I18nProviderWrapper = ({
  children,
  defaultLng,
  branding,
  tenantTranslation,
  localeParam,
}: iI18nProvider) => {
  const isServerSideRendering = useIsServerSideRendering();

  return isServerSideRendering ? (
    <I18nProviderSSR
      defaultLng={defaultLng}
      branding={branding}
      tenantTranslation={tenantTranslation}
      localeParam={localeParam}
    >
      {children}
    </I18nProviderSSR>
  ) : (
    <I18nProvider
      defaultLng={defaultLng}
      branding={branding}
      tenantTranslation={tenantTranslation}
      localeParam={localeParam}
    >
      {children}
    </I18nProvider>
  );
};
