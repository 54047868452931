"use client";

import { GetRotatingBannersResponse } from "@b2bportal/ads-api";
import {
  BaseQueryFn,
  TypedUseQueryHookResult,
} from "@reduxjs/toolkit/dist/query/react";
import { createContext, PropsWithChildren, useContext } from "react";
import { useFetchRotatingBannersQuery } from "./reducer";

export type AdsContextProps = {
  fetchRotatingBannersQuery?: TypedUseQueryHookResult<
    GetRotatingBannersResponse,
    void,
    BaseQueryFn<GetRotatingBannersResponse, void>
  >;
};

export interface IAdsContextProvider {
  adsContext: AdsContextProps;
}

export const defaultAdsContext: AdsContextProps = {
  fetchRotatingBannersQuery: undefined,
};

export const AdsContext = createContext<AdsContextProps>(defaultAdsContext);

export const useAdsContext = () => {
  const ctx = useContext(AdsContext);
  if (ctx === undefined)
    throw new Error(`must be used within a AdsContextProvider`);
  return { ...ctx };
};

export const AdsProvider = ({
  children,
  adsContext,
}: PropsWithChildren<IAdsContextProvider>) => {
  const fetchRotatingBannersQuery = useFetchRotatingBannersQuery();

  return (
    <AdsContext.Provider
      value={{
        ...adsContext,
        fetchRotatingBannersQuery,
      }}
    >
      {children}
    </AdsContext.Provider>
  );
};
