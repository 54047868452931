import {
  AuthMethodEnum,
  CreateSessionRequestEnum,
  GetSessionInfoResponseEnum,
  RefreshSessionResponseEnum,
  SessionInfo,
} from "@b2bportal/auth-api";
import {
  createSession,
  deleteRefreshToken,
  fetchSessionInfo,
  refreshSession,
} from "../api";

export const createAnonymousSession = async (
  locale: string,
  nativeUserId?: string
) => {
  return createSession({
    authMethod: nativeUserId
      ? {
          AuthMethod: AuthMethodEnum.AnonymousHopperApp,
          locale: locale,
          userId: nativeUserId,
        }
      : {
          AuthMethod: AuthMethodEnum.Anonymous,
          locale: locale,
        },
    CreateSessionRequest: CreateSessionRequestEnum.SignIn,
  });
};
export type LoginCallback = (sessionInfo: SessionInfo) => void;
export type ErrorCallback = () => void;

export const fetchSessionAndSetSession = async (
  callback: LoginCallback,
  errorCallback?: ErrorCallback
) => {
  const res = await fetchSessionInfo();
  if (res.GetSessionInfoResponse === GetSessionInfoResponseEnum.Session) {
    callback(res.sessionInfo);
  } else {
    errorCallback?.();
  }
};

export const refreshSessionFromToken = async (
  refreshToken: string,
  onLogin?: LoginCallback,
  onFailure?: () => void
) => {
  deleteRefreshToken();
  const res = await refreshSession({ refreshToken });

  if (
    res.RefreshSessionResponse ===
    RefreshSessionResponseEnum.RefreshSessionFailure
  ) {
    return onFailure?.();
  } else {
    fetchSessionAndSetSession(onLogin);
    return res;
  }
};
