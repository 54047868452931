import {
  ConfigureStoreOptions,
  HopperProviders,
} from "@hopper-b2b/hopper-common";
import { getBrowserSessionId } from "@hopper-b2b/utilities";
import { config } from "@marketing-site/config";
import { GlobalStyles, theme } from "@marketing-site/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { ThemeProvider } from "styled-components";
import { contentfulApi } from "./api/urbana/contentful/apiSlice";
import App from "./app";
import "./index.scss";
import { utilitiesSlice } from "./redux/utilitiesQuery";
import "./styles.scss";

const SERVICE_NAME = "marketing-site";

//Including Redux Dev Tools
declare global {
  interface Window {
    env: Record<string, any>;
    google_optimize: any;
    gtag: any;
  }
}

export const storeOptions: ConfigureStoreOptions = {
  reducers: {
    [contentfulApi.reducerPath]: contentfulApi.reducer,
    utilities: utilitiesSlice.reducer,
  },
  middlewares: [contentfulApi.middleware],
};

// Initialize Datadog Integration
import("@hopper-b2b/datadog").then(({ initialize }) =>
  initialize({
    env: config.environment,
    version: config.appVersion,
    service: SERVICE_NAME,
    sessionId: getBrowserSessionId(),
    enableSessionRecording: true,
    allowedTracingUrls: ["https://staging.hopper.com/", "https://hopper.com/"],
  })
);

// Google Optimize - https://support.google.com/optimize/answer/10106536
const head = document.getElementsByTagName("head")[0];
const googleOptimizeScript = document.createElement("script");
googleOptimizeScript.async = true;
googleOptimizeScript.crossOrigin = "anonymous";
googleOptimizeScript.src =
  "https://www.googleoptimize.com/optimize.js?id=" +
  config.googleOptimizeContainerId;
head.appendChild(googleOptimizeScript);

// reCAPTCHA

// WAF Key
const recaptchaScript = document.createElement("script");
recaptchaScript.async = true;
recaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${config.recaptchaKey}&waf=session`;
document.head.appendChild(recaptchaScript);

(function () {
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.");
    else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ];
      analytics.factory = function (e: any) {
        return function (...args: any) {
          if (window.analytics.initialized) return window.analytics[e](args);
          const i = Array.prototype.slice.call(args);
          i.unshift(e);
          analytics.push(i);
          return analytics;
        };
      };
      for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }
      // Define a method to load Analytics.js from our CDN,
      // and that will be sure to only ever load it once.
      analytics.load = function (key: string, options: any) {
        // Create an async script element based on your key.
        const t = document.createElement("script");
        t.type = "text/javascript";
        t.async = true;
        t.src =
          "https://cdn.segment.com/analytics.js/v1/" +
          key +
          "/analytics.min.js";

        // Insert our script next to the first script element.
        const first = document.getElementsByTagName("script")[0];
        first.parentNode?.insertBefore(t, first);
        analytics._loadOptions = options;
      };
      analytics._writeKey = config.segmentAnalyticsKey;
      analytics.SNIPPET_VERSION = "4.16.1";
      analytics.load(config.segmentAnalyticsKey);
      analytics.page();
    }
})();

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <CompatRouter>
        <HopperProviders
          {...storeOptions}
          recaptchaActionKey={config.recaptchaActionKey}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              {/* <Font /> */}
              <GlobalStyles />
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        </HopperProviders>
      </CompatRouter>
    </BrowserRouter>
  </StrictMode>
);
