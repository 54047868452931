import airlineIcon1L2x from "../../assets/airlines/1L/1L_icon@2x.png";
import airlineIcon1L3x from "../../assets/airlines/1L/1L_icon@3x.png";
import airlineIcon2D2x from "../../assets/airlines/2D/2D_icon@2x.png";
import airlineIcon2D3x from "../../assets/airlines/2D/2D_icon@3x.png";
import airlineIcon2D4x from "../../assets/airlines/2D/2D_icon@4x.png";
import airlineIcon2M2x from "../../assets/airlines/2M/2M_icon@2x.png";
import airlineIcon2M3x from "../../assets/airlines/2M/2M_icon@3x.png";
import airlineIcon2M4x from "../../assets/airlines/2M/2M_icon@4x.png";
import airlineIcon3E2x from "../../assets/airlines/3E/3E_icon@2x.png";
import airlineIcon3E3x from "../../assets/airlines/3E/3E_icon@3x.png";
import airlineIcon3E4x from "../../assets/airlines/3E/3E_icon@4x.png";
import airlineIcon3K2x from "../../assets/airlines/3K/3K_icon@2x.png";
import airlineIcon3K3x from "../../assets/airlines/3K/3K_icon@3x.png";
import airlineIcon3K4x from "../../assets/airlines/3K/3K_icon@4x.png";
import airlineIcon3M2x from "../../assets/airlines/3M/3M_icon@2x.png";
import airlineIcon3M3x from "../../assets/airlines/3M/3M_icon@3x.png";
import airlineIcon3M4x from "../../assets/airlines/3M/3M_icon@4x.png";
import airlineIcon3U2x from "../../assets/airlines/3U/3U_icon@2x.png";
import airlineIcon3U3x from "../../assets/airlines/3U/3U_icon@3x.png";
import airlineIcon3U4x from "../../assets/airlines/3U/3U_icon@4x.png";
import airlineIcon4M2x from "../../assets/airlines/4M/4M_icon@2x.png";
import airlineIcon4M3x from "../../assets/airlines/4M/4M_icon@3x.png";
import airlineIcon4M4x from "../../assets/airlines/4M/4M_icon@4x.png";
import airlineIcon4O2x from "../../assets/airlines/4O/4O_icon@2x.png";
import airlineIcon4O3x from "../../assets/airlines/4O/4O_icon@3x.png";
import airlineIcon4Q2x from "../../assets/airlines/4Q/4Q_icon@2x.png";
import airlineIcon4Q3x from "../../assets/airlines/4Q/4Q_icon@3x.png";
import airlineIcon4Q4x from "../../assets/airlines/4Q/4Q_icon@4x.png";
import airlineIcon4U2x from "../../assets/airlines/4U/4U_icon@2x.png";
import airlineIcon4U3x from "../../assets/airlines/4U/4U_icon@3x.png";
import airlineIcon4U4x from "../../assets/airlines/4U/4U_icon@4x.png";
import airlineIcon5T2x from "../../assets/airlines/5T/5T_icon@2x.png";
import airlineIcon5T3x from "../../assets/airlines/5T/5T_icon@3x.png";
import airlineIcon5T4x from "../../assets/airlines/5T/5T_icon@4x.png";
import airlineIcon7F2x from "../../assets/airlines/7F/7F_icon@2x.png";
import airlineIcon7F3x from "../../assets/airlines/7F/7F_icon@3x.png";
import airlineIcon7F4x from "../../assets/airlines/7F/7F_icon@4x.png";
import airlineIcon7H2x from "../../assets/airlines/7H/7H_icon@2x.png";
import airlineIcon7H3x from "../../assets/airlines/7H/7H_icon@3x.png";
import airlineIcon7H4x from "../../assets/airlines/7H/7H_icon@4x.png";
import airlineIcon7I2x from "../../assets/airlines/7I/7I_icon@2x.png";
import airlineIcon7I3x from "../../assets/airlines/7I/7I_icon@3x.png";
import airlineIcon7I4x from "../../assets/airlines/7I/7I_icon@4x.png";
import airlineIcon8I2x from "../../assets/airlines/8I/8I_icon@2x.png";
import airlineIcon8I3x from "../../assets/airlines/8I/8I_icon@3x.png";
import airlineIcon8I4x from "../../assets/airlines/8I/8I_icon@4x.png";
import airlineIcon8M2x from "../../assets/airlines/8M/8M_icon@2x.png";
import airlineIcon8M3x from "../../assets/airlines/8M/8M_icon@3x.png";
import airlineIcon8M4x from "../../assets/airlines/8M/8M_icon@4x.png";
import airlineIcon8P2x from "../../assets/airlines/8P/8P_icon@2x.png";
import airlineIcon8P3x from "../../assets/airlines/8P/8P_icon@3x.png";
import airlineIcon8P4x from "../../assets/airlines/8P/8P_icon@4x.png";
import airlineIcon8Q2x from "../../assets/airlines/8Q/8Q_icon@2x.png";
import airlineIcon8Q3x from "../../assets/airlines/8Q/8Q_icon@3x.png";
import airlineIcon8Q4x from "../../assets/airlines/8Q/8Q_icon@4x.png";
import airlineIcon9K2x from "../../assets/airlines/9K/9K_icon@2x.png";
import airlineIcon9K3x from "../../assets/airlines/9K/9K_icon@3x.png";
import airlineIcon9K4x from "../../assets/airlines/9K/9K_icon@4x.png";
import airlineIcon9M2x from "../../assets/airlines/9M/9M_icon@2x.png";
import airlineIcon9M3x from "../../assets/airlines/9M/9M_icon@3x.png";
import airlineIcon9M4x from "../../assets/airlines/9M/9M_icon@4x.png";
import airlineIcon9V2x from "../../assets/airlines/9V/9V_icon@2x.png";
import airlineIcon9V3x from "../../assets/airlines/9V/9V_icon@3x.png";
import airlineIcon9V4x from "../../assets/airlines/9V/9V_icon@4x.png";
import airlineIcon9W2x from "../../assets/airlines/9W/9W_icon@2x.png";
import airlineIcon9W3x from "../../assets/airlines/9W/9W_icon@3x.png";
import airlineIcon9W4x from "../../assets/airlines/9W/9W_icon@4x.png";
import airlineIconA32x from "../../assets/airlines/A3/A3_icon@2x.png";
import airlineIconA33x from "../../assets/airlines/A3/A3_icon@3x.png";
import airlineIconA34x from "../../assets/airlines/A3/A3_icon@4x.png";
import airlineIconA92x from "../../assets/airlines/A9/A9_icon@2x.png";
import airlineIconA93x from "../../assets/airlines/A9/A9_icon@3x.png";
import airlineIconA94x from "../../assets/airlines/A9/A9_icon@4x.png";
import airlineIconAA2x from "../../assets/airlines/AA/AA_icon@2x.png";
import airlineIconAA3x from "../../assets/airlines/AA/AA_icon@3x.png";
import airlineIconAA4x from "../../assets/airlines/AA/AA_icon@4x.png";
import airlineIconAB2x from "../../assets/airlines/AB/AB_icon@2x.png";
import airlineIconAB3x from "../../assets/airlines/AB/AB_icon@3x.png";
import airlineIconAB4x from "../../assets/airlines/AB/AB_icon@4x.png";
import airlineIconAC2x from "../../assets/airlines/AC/AC_icon@2x.png";
import airlineIconAC3x from "../../assets/airlines/AC/AC_icon@3x.png";
import airlineIconAC4x from "../../assets/airlines/AC/AC_icon@4x.png";
import airlineIconAD2x from "../../assets/airlines/AD/AD_icon@2x.png";
import airlineIconAD3x from "../../assets/airlines/AD/AD_icon@3x.png";
import airlineIconAD4x from "../../assets/airlines/AD/AD_icon@4x.png";
import airlineIconAF2x from "../../assets/airlines/AF/AF_icon@2x.png";
import airlineIconAF3x from "../../assets/airlines/AF/AF_icon@3x.png";
import airlineIconAF4x from "../../assets/airlines/AF/AF_icon@4x.png";
import airlineIconAH2x from "../../assets/airlines/AH/AH_icon@2x.png";
import airlineIconAH3x from "../../assets/airlines/AH/AH_icon@3x.png";
import airlineIconAH4x from "../../assets/airlines/AH/AH_icon@4x.png";
import airlineIconAI2x from "../../assets/airlines/AI/AI_icon@2x.png";
import airlineIconAI3x from "../../assets/airlines/AI/AI_icon@3x.png";
import airlineIconAI4x from "../../assets/airlines/AI/AI_icon@4x.png";
import airlineIconAM2x from "../../assets/airlines/AM/AM_icon@2x.png";
import airlineIconAM3x from "../../assets/airlines/AM/AM_icon@3x.png";
import airlineIconAM4x from "../../assets/airlines/AM/AM_icon@4x.png";
import airlineIconAN2x from "../../assets/airlines/AN/AN_icon@2x.png";
import airlineIconAN3x from "../../assets/airlines/AN/AN_icon@3x.png";
import airlineIconAN4x from "../../assets/airlines/AN/AN_icon@4x.png";
import airlineIconAR2x from "../../assets/airlines/AR/AR_icon@2x.png";
import airlineIconAR3x from "../../assets/airlines/AR/AR_icon@3x.png";
import airlineIconAR4x from "../../assets/airlines/AR/AR_icon@4x.png";
import airlineIconAS2x from "../../assets/airlines/AS/AS_icon@2x.png";
import airlineIconAS3x from "../../assets/airlines/AS/AS_icon@3x.png";
import airlineIconAS4x from "../../assets/airlines/AS/AS_icon@4x.png";
import airlineIconAT2x from "../../assets/airlines/AT/AT_icon@2x.png";
import airlineIconAT3x from "../../assets/airlines/AT/AT_icon@3x.png";
import airlineIconAT4x from "../../assets/airlines/AT/AT_icon@4x.png";
import airlineIconAV2x from "../../assets/airlines/AV/AV_icon@2x.png";
import airlineIconAV3x from "../../assets/airlines/AV/AV_icon@3x.png";
import airlineIconAV4x from "../../assets/airlines/AV/AV_icon@4x.png";
import airlineIconAY2x from "../../assets/airlines/AY/AY_icon@2x.png";
import airlineIconAY3x from "../../assets/airlines/AY/AY_icon@3x.png";
import airlineIconAY4x from "../../assets/airlines/AY/AY_icon@4x.png";
import airlineIconAZ2x from "../../assets/airlines/AZ/AZ_icon@2x.png";
import airlineIconAZ3x from "../../assets/airlines/AZ/AZ_icon@3x.png";
import airlineIconAZ4x from "../../assets/airlines/AZ/AZ_icon@4x.png";
import airlineIconB22x from "../../assets/airlines/B2/B2_icon@2x.png";
import airlineIconB23x from "../../assets/airlines/B2/B2_icon@3x.png";
import airlineIconB24x from "../../assets/airlines/B2/B2_icon@4x.png";
import airlineIconB62x from "../../assets/airlines/B6/B6_icon@2x.png";
import airlineIconB63x from "../../assets/airlines/B6/B6_icon@3x.png";
import airlineIconB64x from "../../assets/airlines/B6/B6_icon@4x.png";
import airlineIconBA2x from "../../assets/airlines/BA/BA_icon@2x.png";
import airlineIconBA3x from "../../assets/airlines/BA/BA_icon@3x.png";
import airlineIconBA4x from "../../assets/airlines/BA/BA_icon@4x.png";
import airlineIconBB2x from "../../assets/airlines/BB/BB_icon@2x.png";
import airlineIconBB3x from "../../assets/airlines/BB/BB_icon@3x.png";
import airlineIconBB4x from "../../assets/airlines/BB/BB_icon@4x.png";
import airlineIconBE2x from "../../assets/airlines/BE/BE_icon@2x.png";
import airlineIconBE3x from "../../assets/airlines/BE/BE_icon@3x.png";
import airlineIconBE4x from "../../assets/airlines/BE/BE_icon@4x.png";
import airlineIconBF2x from "../../assets/airlines/BF/BF_icon@2x.png";
import airlineIconBF3x from "../../assets/airlines/BF/BF_icon@3x.png";
import airlineIconBF4x from "../../assets/airlines/BF/BF_icon@4x.png";
import airlineIconBI2x from "../../assets/airlines/BI/BI_icon@2x.png";
import airlineIconBI3x from "../../assets/airlines/BI/BI_icon@3x.png";
import airlineIconBI4x from "../../assets/airlines/BI/BI_icon@4x.png";
import airlineIconBL2x from "../../assets/airlines/BL/BL_icon@2x.png";
import airlineIconBL3x from "../../assets/airlines/BL/BL_icon@3x.png";
import airlineIconBL4x from "../../assets/airlines/BL/BL_icon@4x.png";
import airlineIconBM2x from "../../assets/airlines/BM/BM_icon@2x.png";
import airlineIconBM3x from "../../assets/airlines/BM/BM_icon@3x.png";
import airlineIconBM4x from "../../assets/airlines/BM/BM_icon@4x.png";
import airlineIconBP2x from "../../assets/airlines/BP/BP_icon@2x.png";
import airlineIconBP3x from "../../assets/airlines/BP/BP_icon@3x.png";
import airlineIconBP4x from "../../assets/airlines/BP/BP_icon@4x.png";
import airlineIconBR2x from "../../assets/airlines/BR/BR_icon@2x.png";
import airlineIconBR3x from "../../assets/airlines/BR/BR_icon@3x.png";
import airlineIconBR4x from "../../assets/airlines/BR/BR_icon@4x.png";
import airlineIconBT2x from "../../assets/airlines/BT/BT_icon@2x.png";
import airlineIconBT3x from "../../assets/airlines/BT/BT_icon@3x.png";
import airlineIconBT4x from "../../assets/airlines/BT/BT_icon@4x.png";
import airlineIconBW2x from "../../assets/airlines/BW/BW_icon@2x.png";
import airlineIconBW3x from "../../assets/airlines/BW/BW_icon@3x.png";
import airlineIconBW4x from "../../assets/airlines/BW/BW_icon@4x.png";
import airlineIconBX2x from "../../assets/airlines/BX/BX_icon@2x.png";
import airlineIconBX3x from "../../assets/airlines/BX/BX_icon@3x.png";
import airlineIconBX4x from "../../assets/airlines/BX/BX_icon@4x.png";
import airlineIconCA2x from "../../assets/airlines/CA/CA_icon@2x.png";
import airlineIconCA3x from "../../assets/airlines/CA/CA_icon@3x.png";
import airlineIconCA4x from "../../assets/airlines/CA/CA_icon@4x.png";
import airlineIconCI2x from "../../assets/airlines/CI/CI_icon@2x.png";
import airlineIconCI3x from "../../assets/airlines/CI/CI_icon@3x.png";
import airlineIconCI4x from "../../assets/airlines/CI/CI_icon@4x.png";
import airlineIconCM2x from "../../assets/airlines/CM/CM_icon@2x.png";
import airlineIconCM3x from "../../assets/airlines/CM/CM_icon@3x.png";
import airlineIconCM4x from "../../assets/airlines/CM/CM_icon@4x.png";
import airlineIconCX2x from "../../assets/airlines/CX/CX_icon@2x.png";
import airlineIconCX3x from "../../assets/airlines/CX/CX_icon@3x.png";
import airlineIconCX4x from "../../assets/airlines/CX/CX_icon@4x.png";
import airlineIconCZ2x from "../../assets/airlines/CZ/CZ_icon@2x.png";
import airlineIconCZ3x from "../../assets/airlines/CZ/CZ_icon@3x.png";
import airlineIconCZ4x from "../../assets/airlines/CZ/CZ_icon@4x.png";
import airlineIconD82x from "../../assets/airlines/D8/D8_icon@2x.png";
import airlineIconD83x from "../../assets/airlines/D8/D8_icon@3x.png";
import airlineIconD84x from "../../assets/airlines/D8/D8_icon@4x.png";
import airlineIconDE2x from "../../assets/airlines/DE/DE_icon@2x.png";
import airlineIconDE3x from "../../assets/airlines/DE/DE_icon@3x.png";
import airlineIconDE4x from "../../assets/airlines/DE/DE_icon@4x.png";
import airlineIconDI2x from "../../assets/airlines/DI/DI_icon@2x.png";
import airlineIconDI3x from "../../assets/airlines/DI/DI_icon@3x.png";
import airlineIconDL2x from "../../assets/airlines/DL/DL_icon@2x.png";
import airlineIconDL3x from "../../assets/airlines/DL/DL_icon@3x.png";
import airlineIconDL4x from "../../assets/airlines/DL/DL_icon@4x.png";
import airlineIconDN2x from "../../assets/airlines/DN/DN_icon@2x.png";
import airlineIconDN3x from "../../assets/airlines/DN/DN_icon@3x.png";
import airlineIconDT2x from "../../assets/airlines/DT/DT_icon@2x.png";
import airlineIconDT3x from "../../assets/airlines/DT/DT_icon@3x.png";
import airlineIconDT4x from "../../assets/airlines/DT/DT_icon@4x.png";
import airlineIconDY2x from "../../assets/airlines/DY/DY_icon@2x.png";
import airlineIconDY3x from "../../assets/airlines/DY/DY_icon@3x.png";
import airlineIconDY4x from "../../assets/airlines/DY/DY_icon@4x.png";
import airlineIconEB2x from "../../assets/airlines/EB/EB_icon@2x.png";
import airlineIconEB3x from "../../assets/airlines/EB/EB_icon@3x.png";
import airlineIconEB4x from "../../assets/airlines/EB/EB_icon@4x.png";
import airlineIconEI2x from "../../assets/airlines/EI/EI_icon@2x.png";
import airlineIconEI3x from "../../assets/airlines/EI/EI_icon@3x.png";
import airlineIconEI4x from "../../assets/airlines/EI/EI_icon@4x.png";
import airlineIconEK2x from "../../assets/airlines/EK/EK_icon@2x.png";
import airlineIconEK3x from "../../assets/airlines/EK/EK_icon@3x.png";
import airlineIconEK4x from "../../assets/airlines/EK/EK_icon@4x.png";
import airlineIconEQ2x from "../../assets/airlines/EQ/EQ_icon@2x.png";
import airlineIconEQ3x from "../../assets/airlines/EQ/EQ_icon@3x.png";
import airlineIconEQ4x from "../../assets/airlines/EQ/EQ_icon@4x.png";
import airlineIconET2x from "../../assets/airlines/ET/ET_icon@2x.png";
import airlineIconET3x from "../../assets/airlines/ET/ET_icon@3x.png";
import airlineIconET4x from "../../assets/airlines/ET/ET_icon@4x.png";
import airlineIconEW2x from "../../assets/airlines/EW/EW_icon@2x.png";
import airlineIconEW3x from "../../assets/airlines/EW/EW_icon@3x.png";
import airlineIconEW4x from "../../assets/airlines/EW/EW_icon@4x.png";
import airlineIconEY2x from "../../assets/airlines/EY/EY_icon@2x.png";
import airlineIconEY3x from "../../assets/airlines/EY/EY_icon@3x.png";
import airlineIconEY4x from "../../assets/airlines/EY/EY_icon@4x.png";
import airlineIconF72x from "../../assets/airlines/F7/F7_icon@2x.png";
import airlineIconF73x from "../../assets/airlines/F7/F7_icon@3x.png";
import airlineIconF74x from "../../assets/airlines/F7/F7_icon@4x.png";
import airlineIconF82x from "../../assets/airlines/F8/F8_icon@2x.png";
import airlineIconF83x from "../../assets/airlines/F8/F8_icon@3x.png";
import airlineIconF84x from "../../assets/airlines/F8/F8_icon@4x.png";
import airlineIconF92x from "../../assets/airlines/F9/F9_icon@2x.png";
import airlineIconF93x from "../../assets/airlines/F9/F9_icon@3x.png";
import airlineIconF94x from "../../assets/airlines/F9/F9_icon@4x.png";
import airlineIconFB2x from "../../assets/airlines/FB/FB_icon@2x.png";
import airlineIconFB3x from "../../assets/airlines/FB/FB_icon@3x.png";
import airlineIconFB4x from "../../assets/airlines/FB/FB_icon@4x.png";
import airlineIconFI2x from "../../assets/airlines/FI/FI_icon@2x.png";
import airlineIconFI3x from "../../assets/airlines/FI/FI_icon@3x.png";
import airlineIconFI4x from "../../assets/airlines/FI/FI_icon@4x.png";
import airlineIconFJ2x from "../../assets/airlines/FJ/FJ_icon@2x.png";
import airlineIconFJ3x from "../../assets/airlines/FJ/FJ_icon@3x.png";
import airlineIconFJ4x from "../../assets/airlines/FJ/FJ_icon@4x.png";
import airlineIconFR2x from "../../assets/airlines/FR/FR_icon@2x.png";
import airlineIconFR3x from "../../assets/airlines/FR/FR_icon@3x.png";
import airlineIconFR4x from "../../assets/airlines/FR/FR_icon@4x.png";
import airlineIconFS2x from "../../assets/airlines/FS/FS_icon@2x.png";
import airlineIconFS3x from "../../assets/airlines/FS/FS_icon@3x.png";
import airlineIconFS4x from "../../assets/airlines/FS/FS_icon@4x.png";
import airlineIconG32x from "../../assets/airlines/G3/G3_icon@2x.png";
import airlineIconG33x from "../../assets/airlines/G3/G3_icon@3x.png";
import airlineIconG34x from "../../assets/airlines/G3/G3_icon@4x.png";
import airlineIconGA2x from "../../assets/airlines/GA/GA_icon@2x.png";
import airlineIconGA3x from "../../assets/airlines/GA/GA_icon@3x.png";
import airlineIconGA4x from "../../assets/airlines/GA/GA_icon@4x.png";
import airlineIconGE2x from "../../assets/airlines/GE/GE_icon@2x.png";
import airlineIconGE3x from "../../assets/airlines/GE/GE_icon@3x.png";
import airlineIconGE4x from "../../assets/airlines/GE/GE_icon@4x.png";
import airlineIconGF2x from "../../assets/airlines/GF/GF_icon@2x.png";
import airlineIconGF3x from "../../assets/airlines/GF/GF_icon@3x.png";
import airlineIconGF4x from "../../assets/airlines/GF/GF_icon@4x.png";
import airlineIconGK2x from "../../assets/airlines/GK/GK_icon@2x.png";
import airlineIconGK3x from "../../assets/airlines/GK/GK_icon@3x.png";
import airlineIconGK4x from "../../assets/airlines/GK/GK_icon@4x.png";
import airlineIconH12x from "../../assets/airlines/H1/H1_icon@2x.png";
import airlineIconH13x from "../../assets/airlines/H1/H1_icon@3x.png";
import airlineIconH14x from "../../assets/airlines/H1/H1_icon@4x.png";
import airlineIconH22x from "../../assets/airlines/H2/H2_icon@2x.png";
import airlineIconH23x from "../../assets/airlines/H2/H2_icon@3x.png";
import airlineIconH24x from "../../assets/airlines/H2/H2_icon@4x.png";
import airlineIconHA2x from "../../assets/airlines/HA/HA_icon@2x.png";
import airlineIconHA3x from "../../assets/airlines/HA/HA_icon@3x.png";
import airlineIconHA4x from "../../assets/airlines/HA/HA_icon@4x.png";
import airlineIconHM2x from "../../assets/airlines/HM/HM_icon@2x.png";
import airlineIconHM3x from "../../assets/airlines/HM/HM_icon@3x.png";
import airlineIconHM4x from "../../assets/airlines/HM/HM_icon@4x.png";
import airlineIconHU2x from "../../assets/airlines/HU/HU_icon@2x.png";
import airlineIconHU3x from "../../assets/airlines/HU/HU_icon@3x.png";
import airlineIconHU4x from "../../assets/airlines/HU/HU_icon@4x.png";
import airlineIconHV2x from "../../assets/airlines/HV/HV_icon@2x.png";
import airlineIconHV3x from "../../assets/airlines/HV/HV_icon@3x.png";
import airlineIconHV4x from "../../assets/airlines/HV/HV_icon@4x.png";
import airlineIconHX2x from "../../assets/airlines/HX/HX_icon@2x.png";
import airlineIconHX3x from "../../assets/airlines/HX/HX_icon@3x.png";
import airlineIconHX4x from "../../assets/airlines/HX/HX_icon@4x.png";
import airlineIconHY2x from "../../assets/airlines/HY/HY_icon@2x.png";
import airlineIconHY3x from "../../assets/airlines/HY/HY_icon@3x.png";
import airlineIconHY4x from "../../assets/airlines/HY/HY_icon@4x.png";
import airlineIconIB2x from "../../assets/airlines/IB/IB_icon@2x.png";
import airlineIconIB3x from "../../assets/airlines/IB/IB_icon@3x.png";
import airlineIconIB4x from "../../assets/airlines/IB/IB_icon@4x.png";
import airlineIconIG2x from "../../assets/airlines/IG/IG_icon@2x.png";
import airlineIconIG3x from "../../assets/airlines/IG/IG_icon@3x.png";
import airlineIconIG4x from "../../assets/airlines/IG/IG_icon@4x.png";
import airlineIconIZ2x from "../../assets/airlines/IZ/IZ_icon@2x.png";
import airlineIconIZ3x from "../../assets/airlines/IZ/IZ_icon@3x.png";
import airlineIconIZ4x from "../../assets/airlines/IZ/IZ_icon@4x.png";
import airlineIconJ22x from "../../assets/airlines/J2/J2_icon@2x.png";
import airlineIconJ23x from "../../assets/airlines/J2/J2_icon@3x.png";
import airlineIconJ24x from "../../assets/airlines/J2/J2_icon@4x.png";
import airlineIconJA2x from "../../assets/airlines/JA/JA_icon@2x.png";
import airlineIconJA3x from "../../assets/airlines/JA/JA_icon@3x.png";
import airlineIconJA4x from "../../assets/airlines/JA/JA_icon@4x.png";
import airlineIconJJ2x from "../../assets/airlines/JJ/JJ_icon@2x.png";
import airlineIconJJ3x from "../../assets/airlines/JJ/JJ_icon@3x.png";
import airlineIconJJ4x from "../../assets/airlines/JJ/JJ_icon@4x.png";
import airlineIconJL2x from "../../assets/airlines/JL/JL_icon@2x.png";
import airlineIconJL3x from "../../assets/airlines/JL/JL_icon@3x.png";
import airlineIconJL4x from "../../assets/airlines/JL/JL_icon@4x.png";
import airlineIconJP2x from "../../assets/airlines/JP/JP_icon@2x.png";
import airlineIconJP3x from "../../assets/airlines/JP/JP_icon@3x.png";
import airlineIconJP4x from "../../assets/airlines/JP/JP_icon@4x.png";
import airlineIconJQ2x from "../../assets/airlines/JQ/JQ_icon@2x.png";
import airlineIconJQ3x from "../../assets/airlines/JQ/JQ_icon@3x.png";
import airlineIconJQ4x from "../../assets/airlines/JQ/JQ_icon@4x.png";
import airlineIconJU2x from "../../assets/airlines/JU/JU_icon@2x.png";
import airlineIconJU3x from "../../assets/airlines/JU/JU_icon@3x.png";
import airlineIconJU4x from "../../assets/airlines/JU/JU_icon@4x.png";
import airlineIconJV2x from "../../assets/airlines/JV/JV_icon@2x.png";
import airlineIconJV3x from "../../assets/airlines/JV/JV_icon@3x.png";
import airlineIconJV4x from "../../assets/airlines/JV/JV_icon@4x.png";
import airlineIconK52x from "../../assets/airlines/K5/K5_icon@2x.png";
import airlineIconK53x from "../../assets/airlines/K5/K5_icon@3x.png";
import airlineIconK54x from "../../assets/airlines/K5/K5_icon@4x.png";
import airlineIconK62x from "../../assets/airlines/K6/K6_icon@2x.png";
import airlineIconK63x from "../../assets/airlines/K6/K6_icon@3x.png";
import airlineIconK64x from "../../assets/airlines/K6/K6_icon@4x.png";
import airlineIconKA2x from "../../assets/airlines/KA/KA_icon@2x.png";
import airlineIconKA3x from "../../assets/airlines/KA/KA_icon@3x.png";
import airlineIconKA4x from "../../assets/airlines/KA/KA_icon@4x.png";
import airlineIconKC2x from "../../assets/airlines/KC/KC_icon@2x.png";
import airlineIconKC3x from "../../assets/airlines/KC/KC_icon@3x.png";
import airlineIconKC4x from "../../assets/airlines/KC/KC_icon@4x.png";
import airlineIconKE2x from "../../assets/airlines/KE/KE_icon@2x.png";
import airlineIconKE3x from "../../assets/airlines/KE/KE_icon@3x.png";
import airlineIconKE4x from "../../assets/airlines/KE/KE_icon@4x.png";
import airlineIconKL2x from "../../assets/airlines/KL/KL_icon@2x.png";
import airlineIconKL3x from "../../assets/airlines/KL/KL_icon@3x.png";
import airlineIconKL4x from "../../assets/airlines/KL/KL_icon@4x.png";
import airlineIconKM2x from "../../assets/airlines/KM/KM_icon@2x.png";
import airlineIconKM3x from "../../assets/airlines/KM/KM_icon@3x.png";
import airlineIconKM4x from "../../assets/airlines/KM/KM_icon@4x.png";
import airlineIconKQ2x from "../../assets/airlines/KQ/KQ_icon@2x.png";
import airlineIconKQ3x from "../../assets/airlines/KQ/KQ_icon@3x.png";
import airlineIconKQ4x from "../../assets/airlines/KQ/KQ_icon@4x.png";
import airlineIconKS2x from "../../assets/airlines/KS/KS_icon@2x.png";
import airlineIconKS3x from "../../assets/airlines/KS/KS_icon@3x.png";
import airlineIconKS4x from "../../assets/airlines/KS/KS_icon@4x.png";
import airlineIconKU2x from "../../assets/airlines/KU/KU_icon@2x.png";
import airlineIconKU3x from "../../assets/airlines/KU/KU_icon@3x.png";
import airlineIconKU4x from "../../assets/airlines/KU/KU_icon@4x.png";
import airlineIconKX2x from "../../assets/airlines/KX/KX_icon@2x.png";
import airlineIconKX3x from "../../assets/airlines/KX/KX_icon@3x.png";
import airlineIconKX4x from "../../assets/airlines/KX/KX_icon@4x.png";
import airlineIconLA2x from "../../assets/airlines/LA/LA_icon@2x.png";
import airlineIconLA3x from "../../assets/airlines/LA/LA_icon@3x.png";
import airlineIconLA4x from "../../assets/airlines/LA/LA_icon@4x.png";
import airlineIconLG2x from "../../assets/airlines/LG/LG_icon@2x.png";
import airlineIconLG3x from "../../assets/airlines/LG/LG_icon@3x.png";
import airlineIconLG4x from "../../assets/airlines/LG/LG_icon@4x.png";
import airlineIconLH2x from "../../assets/airlines/LH/LH_icon@2x.png";
import airlineIconLH3x from "../../assets/airlines/LH/LH_icon@3x.png";
import airlineIconLH4x from "../../assets/airlines/LH/LH_icon@4x.png";
import airlineIconLI2x from "../../assets/airlines/LI/LI_icon@2x.png";
import airlineIconLI3x from "../../assets/airlines/LI/LI_icon@3x.png";
import airlineIconLI4x from "../../assets/airlines/LI/LI_icon@4x.png";
import airlineIconLO2x from "../../assets/airlines/LO/LO_icon@2x.png";
import airlineIconLO3x from "../../assets/airlines/LO/LO_icon@3x.png";
import airlineIconLO4x from "../../assets/airlines/LO/LO_icon@4x.png";
import airlineIconLR2x from "../../assets/airlines/LR/LR_icon@2x.png";
import airlineIconLR3x from "../../assets/airlines/LR/LR_icon@3x.png";
import airlineIconLR4x from "../../assets/airlines/LR/LR_icon@4x.png";
import airlineIconLS2x from "../../assets/airlines/LS/LS_icon@2x.png";
import airlineIconLS3x from "../../assets/airlines/LS/LS_icon@3x.png";
import airlineIconLS4x from "../../assets/airlines/LS/LS_icon@4x.png";
import airlineIconLT2x from "../../assets/airlines/LT/LT_icon@2x.png";
import airlineIconLT3x from "../../assets/airlines/LT/LT_icon@3x.png";
import airlineIconLT4x from "../../assets/airlines/LT/LT_icon@4x.png";
import airlineIconLX2x from "../../assets/airlines/LX/LX_icon@2x.png";
import airlineIconLX3x from "../../assets/airlines/LX/LX_icon@3x.png";
import airlineIconLX4x from "../../assets/airlines/LX/LX_icon@4x.png";
import airlineIconLY2x from "../../assets/airlines/LY/LY_icon@2x.png";
import airlineIconLY3x from "../../assets/airlines/LY/LY_icon@3x.png";
import airlineIconLY4x from "../../assets/airlines/LY/LY_icon@4x.png";
import airlineIconMD2x from "../../assets/airlines/MD/MD_icon@2x.png";
import airlineIconMD3x from "../../assets/airlines/MD/MD_icon@3x.png";
import airlineIconMD4x from "../../assets/airlines/MD/MD_icon@4x.png";
import airlineIconME2x from "../../assets/airlines/ME/ME_icon@2x.png";
import airlineIconME3x from "../../assets/airlines/ME/ME_icon@3x.png";
import airlineIconME4x from "../../assets/airlines/ME/ME_icon@4x.png";
import airlineIconMF2x from "../../assets/airlines/MF/MF_icon@2x.png";
import airlineIconMF3x from "../../assets/airlines/MF/MF_icon@3x.png";
import airlineIconMF4x from "../../assets/airlines/MF/MF_icon@4x.png";
import airlineIconMH2x from "../../assets/airlines/MH/MH_icon@2x.png";
import airlineIconMH3x from "../../assets/airlines/MH/MH_icon@3x.png";
import airlineIconMH4x from "../../assets/airlines/MH/MH_icon@4x.png";
import airlineIconMI2x from "../../assets/airlines/MI/MI_icon@2x.png";
import airlineIconMI3x from "../../assets/airlines/MI/MI_icon@3x.png";
import airlineIconMI4x from "../../assets/airlines/MI/MI_icon@4x.png";
import airlineIconMJ2x from "../../assets/airlines/MJ/MJ_icon@2x.png";
import airlineIconMJ3x from "../../assets/airlines/MJ/MJ_icon@3x.png";
import airlineIconMJ4x from "../../assets/airlines/MJ/MJ_icon@4x.png";
import airlineIconMK2x from "../../assets/airlines/MK/MK_icon@2x.png";
import airlineIconMK3x from "../../assets/airlines/MK/MK_icon@3x.png";
import airlineIconMK4x from "../../assets/airlines/MK/MK_icon@4x.png";
import airlineIconML2x from "../../assets/airlines/ML/ML_icon@2x.png";
import airlineIconML3x from "../../assets/airlines/ML/ML_icon@3x.png";
import airlineIconML4x from "../../assets/airlines/ML/ML_icon@4x.png";
import airlineIconMS2x from "../../assets/airlines/MS/MS_icon@2x.png";
import airlineIconMS3x from "../../assets/airlines/MS/MS_icon@3x.png";
import airlineIconMS4x from "../../assets/airlines/MS/MS_icon@4x.png";
import airlineIconMT2x from "../../assets/airlines/MT/MT_icon@2x.png";
import airlineIconMT3x from "../../assets/airlines/MT/MT_icon@3x.png";
import airlineIconMT4x from "../../assets/airlines/MT/MT_icon@4x.png";
import airlineIconMU2x from "../../assets/airlines/MU/MU_icon@2x.png";
import airlineIconMU3x from "../../assets/airlines/MU/MU_icon@3x.png";
import airlineIconMU4x from "../../assets/airlines/MU/MU_icon@4x.png";
import airlineIconMW2x from "../../assets/airlines/MW/MW_icon@2x.png";
import airlineIconMW3x from "../../assets/airlines/MW/MW_icon@3x.png";
import airlineIconMW4x from "../../assets/airlines/MW/MW_icon@4x.png";
import airlineIconMX2x from "../../assets/airlines/MX/MX_icon@2x.png";
import airlineIconMX3x from "../../assets/airlines/MX/MX_icon@3x.png";
import airlineIconMX4x from "../../assets/airlines/MX/MX_icon@4x.png";
import airlineIconNF2x from "../../assets/airlines/NF/NF_icon@2x.png";
import airlineIconNF3x from "../../assets/airlines/NF/NF_icon@3x.png";
import airlineIconNF4x from "../../assets/airlines/NF/NF_icon@4x.png";
import airlineIconNH2x from "../../assets/airlines/NH/NH_icon@2x.png";
import airlineIconNH3x from "../../assets/airlines/NH/NH_icon@3x.png";
import airlineIconNH4x from "../../assets/airlines/NH/NH_icon@4x.png";
import airlineIconNK2x from "../../assets/airlines/NK/NK_icon@2x.png";
import airlineIconNK3x from "../../assets/airlines/NK/NK_icon@3x.png";
import airlineIconNK4x from "../../assets/airlines/NK/NK_icon@4x.png";
import airlineIconNT2x from "../../assets/airlines/NT/NT_icon@2x.png";
import airlineIconNT3x from "../../assets/airlines/NT/NT_icon@3x.png";
import airlineIconNT4x from "../../assets/airlines/NT/NT_icon@4x.png";
import airlineIconNX2x from "../../assets/airlines/NX/NX_icon@2x.png";
import airlineIconNX3x from "../../assets/airlines/NX/NX_icon@3x.png";
import airlineIconNX4x from "../../assets/airlines/NX/NX_icon@4x.png";
import airlineIconNZ2x from "../../assets/airlines/NZ/NZ_icon@2x.png";
import airlineIconNZ3x from "../../assets/airlines/NZ/NZ_icon@3x.png";
import airlineIconNZ4x from "../../assets/airlines/NZ/NZ_icon@4x.png";
import airlineIconO62x from "../../assets/airlines/O6/O6_icon@2x.png";
import airlineIconO63x from "../../assets/airlines/O6/O6_icon@3x.png";
import airlineIconO64x from "../../assets/airlines/O6/O6_icon@4x.png";
import airlineIconOA2x from "../../assets/airlines/OA/OA_icon@2x.png";
import airlineIconOA3x from "../../assets/airlines/OA/OA_icon@3x.png";
import airlineIconOA4x from "../../assets/airlines/OA/OA_icon@4x.png";
import airlineIconOK2x from "../../assets/airlines/OK/OK_icon@2x.png";
import airlineIconOK3x from "../../assets/airlines/OK/OK_icon@3x.png";
import airlineIconOK4x from "../../assets/airlines/OK/OK_icon@4x.png";
import airlineIconOS2x from "../../assets/airlines/OS/OS_icon@2x.png";
import airlineIconOS3x from "../../assets/airlines/OS/OS_icon@3x.png";
import airlineIconOS4x from "../../assets/airlines/OS/OS_icon@4x.png";
import airlineIconOU2x from "../../assets/airlines/OU/OU_icon@2x.png";
import airlineIconOU3x from "../../assets/airlines/OU/OU_icon@3x.png";
import airlineIconOU4x from "../../assets/airlines/OU/OU_icon@4x.png";
import airlineIconOV2x from "../../assets/airlines/OV/OV_icon@2x.png";
import airlineIconOV3x from "../../assets/airlines/OV/OV_icon@3x.png";
import airlineIconOV4x from "../../assets/airlines/OV/OV_icon@4x.png";
import airlineIconOY2x from "../../assets/airlines/OY/OY_icon@2x.png";
import airlineIconOY3x from "../../assets/airlines/OY/OY_icon@3x.png";
import airlineIconOY4x from "../../assets/airlines/OY/OY_icon@4x.png";
import airlineIconOZ2x from "../../assets/airlines/OZ/OZ_icon@2x.png";
import airlineIconOZ3x from "../../assets/airlines/OZ/OZ_icon@3x.png";
import airlineIconOZ4x from "../../assets/airlines/OZ/OZ_icon@4x.png";
import airlineIconP92x from "../../assets/airlines/P9/P9_icon@2x.png";
import airlineIconP93x from "../../assets/airlines/P9/P9_icon@3x.png";
import airlineIconP94x from "../../assets/airlines/P9/P9_icon@4x.png";
import airlineIconPC2x from "../../assets/airlines/PC/PC_icon@2x.png";
import airlineIconPC3x from "../../assets/airlines/PC/PC_icon@3x.png";
import airlineIconPC4x from "../../assets/airlines/PC/PC_icon@4x.png";
import airlineIconPD2x from "../../assets/airlines/PD/PD_icon@2x.png";
import airlineIconPD3x from "../../assets/airlines/PD/PD_icon@3x.png";
import airlineIconPD4x from "../../assets/airlines/PD/PD_icon@4x.png";
import airlineIconPG2x from "../../assets/airlines/PG/PG_icon@2x.png";
import airlineIconPG3x from "../../assets/airlines/PG/PG_icon@3x.png";
import airlineIconPG4x from "../../assets/airlines/PG/PG_icon@4x.png";
import airlineIconPK2x from "../../assets/airlines/PK/PK_icon@2x.png";
import airlineIconPK3x from "../../assets/airlines/PK/PK_icon@3x.png";
import airlineIconPK4x from "../../assets/airlines/PK/PK_icon@4x.png";
import airlineIconPLACEHOLDER2x from "../../assets/airlines/PLACEHOLDER/PLACEHOLDER_icon@2x.png";
import airlineIconPLACEHOLDER3x from "../../assets/airlines/PLACEHOLDER/PLACEHOLDER_icon@3x.png";
import airlineIconPLACEHOLDER4x from "../../assets/airlines/PLACEHOLDER/PLACEHOLDER_icon@4x.png";
import airlineIconPR2x from "../../assets/airlines/PR/PR_icon@2x.png";
import airlineIconPR3x from "../../assets/airlines/PR/PR_icon@3x.png";
import airlineIconPR4x from "../../assets/airlines/PR/PR_icon@4x.png";
import airlineIconPS2x from "../../assets/airlines/PS/PS_icon@2x.png";
import airlineIconPS3x from "../../assets/airlines/PS/PS_icon@3x.png";
import airlineIconPS4x from "../../assets/airlines/PS/PS_icon@4x.png";
import airlineIconPU2x from "../../assets/airlines/PU/PU_icon@2x.png";
import airlineIconPU3x from "../../assets/airlines/PU/PU_icon@3x.png";
import airlineIconPU4x from "../../assets/airlines/PU/PU_icon@4x.png";
import airlineIconPX2x from "../../assets/airlines/PX/PX_icon@2x.png";
import airlineIconPX3x from "../../assets/airlines/PX/PX_icon@3x.png";
import airlineIconPX4x from "../../assets/airlines/PX/PX_icon@4x.png";
import airlineIconPY2x from "../../assets/airlines/PY/PY_icon@2x.png";
import airlineIconPY3x from "../../assets/airlines/PY/PY_icon@3x.png";
import airlineIconPY4x from "../../assets/airlines/PY/PY_icon@4x.png";
import airlineIconPZ2x from "../../assets/airlines/PZ/PZ_icon@2x.png";
import airlineIconPZ3x from "../../assets/airlines/PZ/PZ_icon@3x.png";
import airlineIconPZ4x from "../../assets/airlines/PZ/PZ_icon@4x.png";
import airlineIconQF2x from "../../assets/airlines/QF/QF_icon@2x.png";
import airlineIconQF3x from "../../assets/airlines/QF/QF_icon@3x.png";
import airlineIconQF4x from "../../assets/airlines/QF/QF_icon@4x.png";
import airlineIconQR2x from "../../assets/airlines/QR/QR_icon@2x.png";
import airlineIconQR3x from "../../assets/airlines/QR/QR_icon@3x.png";
import airlineIconQR4x from "../../assets/airlines/QR/QR_icon@4x.png";
import airlineIconQV2x from "../../assets/airlines/QV/QV_icon@2x.png";
import airlineIconQV3x from "../../assets/airlines/QV/QV_icon@3x.png";
import airlineIconQV4x from "../../assets/airlines/QV/QV_icon@4x.png";
import airlineIconR72x from "../../assets/airlines/R7/R7_icon@2x.png";
import airlineIconR73x from "../../assets/airlines/R7/R7_icon@3x.png";
import airlineIconR74x from "../../assets/airlines/R7/R7_icon@4x.png";
import airlineIconRJ2x from "../../assets/airlines/RJ/RJ_icon@2x.png";
import airlineIconRJ3x from "../../assets/airlines/RJ/RJ_icon@3x.png";
import airlineIconRJ4x from "../../assets/airlines/RJ/RJ_icon@4x.png";
import airlineIconRO2x from "../../assets/airlines/RO/RO_icon@2x.png";
import airlineIconRO3x from "../../assets/airlines/RO/RO_icon@3x.png";
import airlineIconRO4x from "../../assets/airlines/RO/RO_icon@4x.png";
import airlineIconS32x from "../../assets/airlines/S3/S3_icon@2x.png";
import airlineIconS33x from "../../assets/airlines/S3/S3_icon@3x.png";
import airlineIconS34x from "../../assets/airlines/S3/S3_icon@4x.png";
import airlineIconS42x from "../../assets/airlines/S4/S4_icon@2x.png";
import airlineIconS43x from "../../assets/airlines/S4/S4_icon@3x.png";
import airlineIconS44x from "../../assets/airlines/S4/S4_icon@4x.png";
import airlineIconS72x from "../../assets/airlines/S7/S7_icon@2x.png";
import airlineIconS73x from "../../assets/airlines/S7/S7_icon@3x.png";
import airlineIconS74x from "../../assets/airlines/S7/S7_icon@4x.png";
import airlineIconSA2x from "../../assets/airlines/SA/SA_icon@2x.png";
import airlineIconSA3x from "../../assets/airlines/SA/SA_icon@3x.png";
import airlineIconSA4x from "../../assets/airlines/SA/SA_icon@4x.png";
import airlineIconSE2x from "../../assets/airlines/SE/SE_icon@2x.png";
import airlineIconSE3x from "../../assets/airlines/SE/SE_icon@3x.png";
import airlineIconSE4x from "../../assets/airlines/SE/SE_icon@4x.png";
import airlineIconSK2x from "../../assets/airlines/SK/SK_icon@2x.png";
import airlineIconSK3x from "../../assets/airlines/SK/SK_icon@3x.png";
import airlineIconSK4x from "../../assets/airlines/SK/SK_icon@4x.png";
import airlineIconSN2x from "../../assets/airlines/SN/SN_icon@2x.png";
import airlineIconSN3x from "../../assets/airlines/SN/SN_icon@3x.png";
import airlineIconSN4x from "../../assets/airlines/SN/SN_icon@4x.png";
import airlineIconSQ2x from "../../assets/airlines/SQ/SQ_icon@2x.png";
import airlineIconSQ3x from "../../assets/airlines/SQ/SQ_icon@3x.png";
import airlineIconSQ4x from "../../assets/airlines/SQ/SQ_icon@4x.png";
import airlineIconSS2x from "../../assets/airlines/SS/SS_icon@2x.png";
import airlineIconSS3x from "../../assets/airlines/SS/SS_icon@3x.png";
import airlineIconSS4x from "../../assets/airlines/SS/SS_icon@4x.png";
import airlineIconST2x from "../../assets/airlines/ST/ST_icon@2x.png";
import airlineIconST3x from "../../assets/airlines/ST/ST_icon@3x.png";
import airlineIconST4x from "../../assets/airlines/ST/ST_icon@4x.png";
import airlineIconSU2x from "../../assets/airlines/SU/SU_icon@2x.png";
import airlineIconSU3x from "../../assets/airlines/SU/SU_icon@3x.png";
import airlineIconSU4x from "../../assets/airlines/SU/SU_icon@4x.png";
import airlineIconSV2x from "../../assets/airlines/SV/SV_icon@2x.png";
import airlineIconSV3x from "../../assets/airlines/SV/SV_icon@3x.png";
import airlineIconSV4x from "../../assets/airlines/SV/SV_icon@4x.png";
import airlineIconSW2x from "../../assets/airlines/SW/SW_icon@2x.png";
import airlineIconSW3x from "../../assets/airlines/SW/SW_icon@3x.png";
import airlineIconSW4x from "../../assets/airlines/SW/SW_icon@4x.png";
import airlineIconSX2x from "../../assets/airlines/SX/SX_icon@2x.png";
import airlineIconSX3x from "../../assets/airlines/SX/SX_icon@3x.png";
import airlineIconSX4x from "../../assets/airlines/SX/SX_icon@4x.png";
import airlineIconSY2x from "../../assets/airlines/SY/SY_icon@2x.png";
import airlineIconSY3x from "../../assets/airlines/SY/SY_icon@3x.png";
import airlineIconSY4x from "../../assets/airlines/SY/SY_icon@4x.png";
import airlineIconTA2x from "../../assets/airlines/TA/TA_icon@2x.png";
import airlineIconTA3x from "../../assets/airlines/TA/TA_icon@3x.png";
import airlineIconTA4x from "../../assets/airlines/TA/TA_icon@4x.png";
import airlineIconTB2x from "../../assets/airlines/TB/TB_icon@2x.png";
import airlineIconTB3x from "../../assets/airlines/TB/TB_icon@3x.png";
import airlineIconTB4x from "../../assets/airlines/TB/TB_icon@4x.png";
import airlineIconTF2x from "../../assets/airlines/TF/TF_icon@2x.png";
import airlineIconTF3x from "../../assets/airlines/TF/TF_icon@3x.png";
import airlineIconTF4x from "../../assets/airlines/TF/TF_icon@4x.png";
import airlineIconTG2x from "../../assets/airlines/TG/TG_icon@2x.png";
import airlineIconTG3x from "../../assets/airlines/TG/TG_icon@3x.png";
import airlineIconTG4x from "../../assets/airlines/TG/TG_icon@4x.png";
import airlineIconTK2x from "../../assets/airlines/TK/TK_icon@2x.png";
import airlineIconTK3x from "../../assets/airlines/TK/TK_icon@3x.png";
import airlineIconTK4x from "../../assets/airlines/TK/TK_icon@4x.png";
import airlineIconTN2x from "../../assets/airlines/TN/TN_icon@2x.png";
import airlineIconTN3x from "../../assets/airlines/TN/TN_icon@3x.png";
import airlineIconTN4x from "../../assets/airlines/TN/TN_icon@4x.png";
import airlineIconTO2x from "../../assets/airlines/TO/TO_icon@2x.png";
import airlineIconTO3x from "../../assets/airlines/TO/TO_icon@3x.png";
import airlineIconTO4x from "../../assets/airlines/TO/TO_icon@4x.png";
import airlineIconTP2x from "../../assets/airlines/TP/TP_icon@2x.png";
import airlineIconTP3x from "../../assets/airlines/TP/TP_icon@3x.png";
import airlineIconTP4x from "../../assets/airlines/TP/TP_icon@4x.png";
import airlineIconTS2x from "../../assets/airlines/TS/TS_icon@2x.png";
import airlineIconTS3x from "../../assets/airlines/TS/TS_icon@3x.png";
import airlineIconTS4x from "../../assets/airlines/TS/TS_icon@4x.png";
import airlineIconTU2x from "../../assets/airlines/TU/TU_icon@2x.png";
import airlineIconTU3x from "../../assets/airlines/TU/TU_icon@3x.png";
import airlineIconTU4x from "../../assets/airlines/TU/TU_icon@4x.png";
import airlineIconTX2x from "../../assets/airlines/TX/TX_icon@2x.png";
import airlineIconTX3x from "../../assets/airlines/TX/TX_icon@3x.png";
import airlineIconTX4x from "../../assets/airlines/TX/TX_icon@4x.png";
import airlineIconU22x from "../../assets/airlines/U2/U2_icon@2x.png";
import airlineIconU23x from "../../assets/airlines/U2/U2_icon@3x.png";
import airlineIconU24x from "../../assets/airlines/U2/U2_icon@4x.png";
import airlineIconU62x from "../../assets/airlines/U6/U6_icon@2x.png";
import airlineIconU63x from "../../assets/airlines/U6/U6_icon@3x.png";
import airlineIconU64x from "../../assets/airlines/U6/U6_icon@4x.png";
import airlineIconUA2x from "../../assets/airlines/UA/UA_icon@2x.png";
import airlineIconUA3x from "../../assets/airlines/UA/UA_icon@3x.png";
import airlineIconUA4x from "../../assets/airlines/UA/UA_icon@4x.png";
import airlineIconUL2x from "../../assets/airlines/UL/UL_icon@2x.png";
import airlineIconUL3x from "../../assets/airlines/UL/UL_icon@3x.png";
import airlineIconUL4x from "../../assets/airlines/UL/UL_icon@4x.png";
import airlineIconUN2x from "../../assets/airlines/UN/UN_icon@2x.png";
import airlineIconUN3x from "../../assets/airlines/UN/UN_icon@3x.png";
import airlineIconUN4x from "../../assets/airlines/UN/UN_icon@4x.png";
import airlineIconUO2x from "../../assets/airlines/UO/UO_icon@2x.png";
import airlineIconUO3x from "../../assets/airlines/UO/UO_icon@3x.png";
import airlineIconUO4x from "../../assets/airlines/UO/UO_icon@4x.png";
import airlineIconUP2x from "../../assets/airlines/UP/UP_icon@2x.png";
import airlineIconUP3x from "../../assets/airlines/UP/UP_icon@3x.png";
import airlineIconUP4x from "../../assets/airlines/UP/UP_icon@4x.png";
import airlineIconUS2x from "../../assets/airlines/US/US_icon@2x.png";
import airlineIconUS3x from "../../assets/airlines/US/US_icon@3x.png";
import airlineIconUS4x from "../../assets/airlines/US/US_icon@4x.png";
import airlineIconUU2x from "../../assets/airlines/UU/UU_icon@2x.png";
import airlineIconUU3x from "../../assets/airlines/UU/UU_icon@3x.png";
import airlineIconUU4x from "../../assets/airlines/UU/UU_icon@4x.png";
import airlineIconUX2x from "../../assets/airlines/UX/UX_icon@2x.png";
import airlineIconUX3x from "../../assets/airlines/UX/UX_icon@3x.png";
import airlineIconUX4x from "../../assets/airlines/UX/UX_icon@4x.png";
import airlineIconV72x from "../../assets/airlines/V7/V7_icon@2x.png";
import airlineIconV73x from "../../assets/airlines/V7/V7_icon@3x.png";
import airlineIconV74x from "../../assets/airlines/V7/V7_icon@4x.png";
import airlineIconVA2x from "../../assets/airlines/VA/VA_icon@2x.png";
import airlineIconVA3x from "../../assets/airlines/VA/VA_icon@3x.png";
import airlineIconVA4x from "../../assets/airlines/VA/VA_icon@4x.png";
import airlineIconVB2x from "../../assets/airlines/VB/VB_icon@2x.png";
import airlineIconVB3x from "../../assets/airlines/VB/VB_icon@3x.png";
import airlineIconVB4x from "../../assets/airlines/VB/VB_icon@4x.png";
import airlineIconVH2x from "../../assets/airlines/VH/VH_icon@2x.png";
import airlineIconVH3x from "../../assets/airlines/VH/VH_icon@3x.png";
import airlineIconVH4x from "../../assets/airlines/VH/VH_icon@4x.png";
import airlineIconVN2x from "../../assets/airlines/VN/VN_icon@2x.png";
import airlineIconVN3x from "../../assets/airlines/VN/VN_icon@3x.png";
import airlineIconVN4x from "../../assets/airlines/VN/VN_icon@4x.png";
import airlineIconVS2x from "../../assets/airlines/VS/VS_icon@2x.png";
import airlineIconVS3x from "../../assets/airlines/VS/VS_icon@3x.png";
import airlineIconVS4x from "../../assets/airlines/VS/VS_icon@4x.png";
import airlineIconVV2x from "../../assets/airlines/VV/VV_icon@2x.png";
import airlineIconVV3x from "../../assets/airlines/VV/VV_icon@3x.png";
import airlineIconVV4x from "../../assets/airlines/VV/VV_icon@4x.png";
import airlineIconVW2x from "../../assets/airlines/VW/VW_icon@2x.png";
import airlineIconVW3x from "../../assets/airlines/VW/VW_icon@3x.png";
import airlineIconVW4x from "../../assets/airlines/VW/VW_icon@4x.png";
import airlineIconVX2x from "../../assets/airlines/VX/VX_icon@2x.png";
import airlineIconVX3x from "../../assets/airlines/VX/VX_icon@3x.png";
import airlineIconVX4x from "../../assets/airlines/VX/VX_icon@4x.png";
import airlineIconVY2x from "../../assets/airlines/VY/VY_icon@2x.png";
import airlineIconVY3x from "../../assets/airlines/VY/VY_icon@3x.png";
import airlineIconVY4x from "../../assets/airlines/VY/VY_icon@4x.png";
import airlineIconW32x from "../../assets/airlines/W3/W3_icon@2x.png";
import airlineIconW33x from "../../assets/airlines/W3/W3_icon@3x.png";
import airlineIconW34x from "../../assets/airlines/W3/W3_icon@4x.png";
import airlineIconW62x from "../../assets/airlines/W6/W6_icon@2x.png";
import airlineIconW63x from "../../assets/airlines/W6/W6_icon@3x.png";
import airlineIconW64x from "../../assets/airlines/W6/W6_icon@4x.png";
import airlineIconW92x from "../../assets/airlines/W9/W9_icon@2x.png";
import airlineIconW93x from "../../assets/airlines/W9/W9_icon@3x.png";
import airlineIconW94x from "../../assets/airlines/W9/W9_icon@4x.png";
import airlineIconWG2x from "../../assets/airlines/WG/WG_icon@2x.png";
import airlineIconWG3x from "../../assets/airlines/WG/WG_icon@3x.png";
import airlineIconWG4x from "../../assets/airlines/WG/WG_icon@4x.png";
import airlineIconWM2x from "../../assets/airlines/WM/WM_icon@2x.png";
import airlineIconWM3x from "../../assets/airlines/WM/WM_icon@3x.png";
import airlineIconWM4x from "../../assets/airlines/WM/WM_icon@4x.png";
import airlineIconWN2x from "../../assets/airlines/WN/WN_icon@2x.png";
import airlineIconWN3x from "../../assets/airlines/WN/WN_icon@3x.png";
import airlineIconWN4x from "../../assets/airlines/WN/WN_icon@4x.png";
import airlineIconWO2x from "../../assets/airlines/WO/WO_icon@2x.png";
import airlineIconWO3x from "../../assets/airlines/WO/WO_icon@3x.png";
import airlineIconWO4x from "../../assets/airlines/WO/WO_icon@4x.png";
import airlineIconWP2x from "../../assets/airlines/WP/WP_icon@2x.png";
import airlineIconWP3x from "../../assets/airlines/WP/WP_icon@3x.png";
import airlineIconWP4x from "../../assets/airlines/WP/WP_icon@4x.png";
import airlineIconWS2x from "../../assets/airlines/WS/WS_icon@2x.png";
import airlineIconWS3x from "../../assets/airlines/WS/WS_icon@3x.png";
import airlineIconWS4x from "../../assets/airlines/WS/WS_icon@4x.png";
import airlineIconWW2x from "../../assets/airlines/WW/WW_icon@2x.png";
import airlineIconWW3x from "../../assets/airlines/WW/WW_icon@3x.png";
import airlineIconWW4x from "../../assets/airlines/WW/WW_icon@4x.png";
import airlineIconWX2x from "../../assets/airlines/WX/WX_icon@2x.png";
import airlineIconWX3x from "../../assets/airlines/WX/WX_icon@3x.png";
import airlineIconWX4x from "../../assets/airlines/WX/WX_icon@4x.png";
import airlineIconWY2x from "../../assets/airlines/WY/WY_icon@2x.png";
import airlineIconWY3x from "../../assets/airlines/WY/WY_icon@3x.png";
import airlineIconWY4x from "../../assets/airlines/WY/WY_icon@4x.png";
import airlineIconXG2x from "../../assets/airlines/XG/XG_icon@2x.png";
import airlineIconXG3x from "../../assets/airlines/XG/XG_icon@3x.png";
import airlineIconXG4x from "../../assets/airlines/XG/XG_icon@4x.png";
import airlineIconXL2x from "../../assets/airlines/XL/XL_icon@2x.png";
import airlineIconXL3x from "../../assets/airlines/XL/XL_icon@3x.png";
import airlineIconXL4x from "../../assets/airlines/XL/XL_icon@4x.png";
import airlineIconXP2x from "../../assets/airlines/XP/XP_icon@2x.png";
import airlineIconXP3x from "../../assets/airlines/XP/XP_icon@3x.png";
import airlineIconXP4x from "../../assets/airlines/XP/XP_icon@4x.png";
import airlineIconXQ2x from "../../assets/airlines/XQ/XQ_icon@2x.png";
import airlineIconXQ3x from "../../assets/airlines/XQ/XQ_icon@3x.png";
import airlineIconXQ4x from "../../assets/airlines/XQ/XQ_icon@4x.png";
import airlineIconY42x from "../../assets/airlines/Y4/Y4_icon@2x.png";
import airlineIconY43x from "../../assets/airlines/Y4/Y4_icon@3x.png";
import airlineIconY44x from "../../assets/airlines/Y4/Y4_icon@4x.png";
import airlineIconYO2x from "../../assets/airlines/YO/YO_icon@2x.png";
import airlineIconYO3x from "../../assets/airlines/YO/YO_icon@3x.png";
import airlineIconYO4x from "../../assets/airlines/YO/YO_icon@4x.png";
import airlineIconYQ2x from "../../assets/airlines/YQ/YQ_icon@2x.png";
import airlineIconYQ3x from "../../assets/airlines/YQ/YQ_icon@3x.png";
import airlineIconYQ4x from "../../assets/airlines/YQ/YQ_icon@4x.png";
import airlineIconZ82x from "../../assets/airlines/Z8/Z8_icon@2x.png";
import airlineIconZ83x from "../../assets/airlines/Z8/Z8_icon@3x.png";
import airlineIconZ84x from "../../assets/airlines/Z8/Z8_icon@4x.png";
import airlineIconZI2x from "../../assets/airlines/ZI/ZI_icon@2x.png";
import airlineIconZI3x from "../../assets/airlines/ZI/ZI_icon@3x.png";
import airlineIconZI4x from "../../assets/airlines/ZI/ZI_icon@4x.png";
import airlineIconZK2x from "../../assets/airlines/ZK/ZK_icon@2x.png";
import airlineIconZK3x from "../../assets/airlines/ZK/ZK_icon@3x.png";
import airlineIconZK4x from "../../assets/airlines/ZK/ZK_icon@4x.png";
import airlineIconZL2x from "../../assets/airlines/ZL/ZL_icon@2x.png";
import airlineIconZL3x from "../../assets/airlines/ZL/ZL_icon@3x.png";
import airlineIconZL4x from "../../assets/airlines/ZL/ZL_icon@4x.png";

const iconNameToSourceMap: { [key: string]: string } = {
  "PLACEHOLDER-2x": airlineIconPLACEHOLDER2x,
  "PLACEHOLDER-3x": airlineIconPLACEHOLDER3x,
  "PLACEHOLDER-4x": airlineIconPLACEHOLDER4x,
  "YQ-2x": airlineIconYQ2x,
  "YQ-3x": airlineIconYQ3x,
  "YQ-4x": airlineIconYQ4x,
  "Y4-2x": airlineIconY42x,
  "Y4-3x": airlineIconY43x,
  "Y4-4x": airlineIconY44x,
  "VB-2x": airlineIconVB2x,
  "VB-3x": airlineIconVB3x,
  "VB-4x": airlineIconVB4x,
  "XP-2x": airlineIconXP2x,
  "XP-3x": airlineIconXP3x,
  "XP-4x": airlineIconXP4x,
  "F8-2x": airlineIconF82x,
  "F8-3x": airlineIconF83x,
  "F8-4x": airlineIconF84x,
  "VV-2x": airlineIconVV2x,
  "VV-3x": airlineIconVV3x,
  "VV-4x": airlineIconVV4x,
  "VH-2x": airlineIconVH2x,
  "VH-3x": airlineIconVH3x,
  "VH-4x": airlineIconVH4x,
  "TO-2x": airlineIconTO2x,
  "TO-3x": airlineIconTO3x,
  "TO-4x": airlineIconTO4x,
  "JA-2x": airlineIconJA2x,
  "JA-3x": airlineIconJA3x,
  "JA-4x": airlineIconJA4x,
  "HV-2x": airlineIconHV2x,
  "HV-3x": airlineIconHV3x,
  "HV-4x": airlineIconHV4x,
  "2D-2x": airlineIcon2D2x,
  "2D-3x": airlineIcon2D3x,
  "2D-4x": airlineIcon2D4x,
  "1L-2x": airlineIcon1L2x,
  "1L-3x": airlineIcon1L3x,
  "2M-2x": airlineIcon2M2x,
  "2M-3x": airlineIcon2M3x,
  "2M-4x": airlineIcon2M4x,
  "3E-2x": airlineIcon3E2x,
  "3E-3x": airlineIcon3E3x,
  "3E-4x": airlineIcon3E4x,
  "3K-2x": airlineIcon3K2x,
  "3K-3x": airlineIcon3K3x,
  "3K-4x": airlineIcon3K4x,
  "3M-2x": airlineIcon3M2x,
  "3M-3x": airlineIcon3M3x,
  "3M-4x": airlineIcon3M4x,
  "3U-2x": airlineIcon3U2x,
  "3U-3x": airlineIcon3U3x,
  "3U-4x": airlineIcon3U4x,
  "4M-2x": airlineIcon4M2x,
  "4M-3x": airlineIcon4M3x,
  "4M-4x": airlineIcon4M4x,
  "4O-2x": airlineIcon4O2x,
  "4O-3x": airlineIcon4O3x,
  "4Q-2x": airlineIcon4Q2x,
  "4Q-3x": airlineIcon4Q3x,
  "4Q-4x": airlineIcon4Q4x,
  "4U-2x": airlineIcon4U2x,
  "4U-3x": airlineIcon4U3x,
  "4U-4x": airlineIcon4U4x,
  "5T-2x": airlineIcon5T2x,
  "5T-3x": airlineIcon5T3x,
  "5T-4x": airlineIcon5T4x,
  "7F-2x": airlineIcon7F2x,
  "7F-3x": airlineIcon7F3x,
  "7F-4x": airlineIcon7F4x,
  "7H-2x": airlineIcon7H2x,
  "7H-3x": airlineIcon7H3x,
  "7H-4x": airlineIcon7H4x,
  "7I-2x": airlineIcon7I2x,
  "7I-3x": airlineIcon7I3x,
  "7I-4x": airlineIcon7I4x,
  "8I-2x": airlineIcon8I2x,
  "8I-3x": airlineIcon8I3x,
  "8I-4x": airlineIcon8I4x,
  "8M-2x": airlineIcon8M2x,
  "8M-3x": airlineIcon8M3x,
  "8M-4x": airlineIcon8M4x,
  "8P-2x": airlineIcon8P2x,
  "8P-3x": airlineIcon8P3x,
  "8P-4x": airlineIcon8P4x,
  "8Q-2x": airlineIcon8Q2x,
  "8Q-3x": airlineIcon8Q3x,
  "8Q-4x": airlineIcon8Q4x,
  "9K-2x": airlineIcon9K2x,
  "9K-3x": airlineIcon9K3x,
  "9K-4x": airlineIcon9K4x,
  "9M-2x": airlineIcon9M2x,
  "9M-3x": airlineIcon9M3x,
  "9M-4x": airlineIcon9M4x,
  "9V-2x": airlineIcon9V2x,
  "9V-3x": airlineIcon9V3x,
  "9V-4x": airlineIcon9V4x,
  "9W-2x": airlineIcon9W2x,
  "9W-3x": airlineIcon9W3x,
  "9W-4x": airlineIcon9W4x,
  "A3-2x": airlineIconA32x,
  "A3-3x": airlineIconA33x,
  "A3-4x": airlineIconA34x,
  "A9-2x": airlineIconA92x,
  "A9-3x": airlineIconA93x,
  "A9-4x": airlineIconA94x,
  "AA-2x": airlineIconAA2x,
  "AA-3x": airlineIconAA3x,
  "AA-4x": airlineIconAA4x,
  "AB-2x": airlineIconAB2x,
  "AB-3x": airlineIconAB3x,
  "AB-4x": airlineIconAB4x,
  "AC-2x": airlineIconAC2x,
  "AC-3x": airlineIconAC3x,
  "AC-4x": airlineIconAC4x,
  "AD-2x": airlineIconAD2x,
  "AD-3x": airlineIconAD3x,
  "AD-4x": airlineIconAD4x,
  "AF-2x": airlineIconAF2x,
  "AF-3x": airlineIconAF3x,
  "AF-4x": airlineIconAF4x,
  "AH-2x": airlineIconAH2x,
  "AH-3x": airlineIconAH3x,
  "AH-4x": airlineIconAH4x,
  "AI-2x": airlineIconAI2x,
  "AI-3x": airlineIconAI3x,
  "AI-4x": airlineIconAI4x,
  "AM-2x": airlineIconAM2x,
  "AM-3x": airlineIconAM3x,
  "AM-4x": airlineIconAM4x,
  "AN-2x": airlineIconAN2x,
  "AN-3x": airlineIconAN3x,
  "AN-4x": airlineIconAN4x,
  "AR-2x": airlineIconAR2x,
  "AR-3x": airlineIconAR3x,
  "AR-4x": airlineIconAR4x,
  "AS-2x": airlineIconAS2x,
  "AS-3x": airlineIconAS3x,
  "AS-4x": airlineIconAS4x,
  "AT-2x": airlineIconAT2x,
  "AT-3x": airlineIconAT3x,
  "AT-4x": airlineIconAT4x,
  "AV-2x": airlineIconAV2x,
  "AV-3x": airlineIconAV3x,
  "AV-4x": airlineIconAV4x,
  "AY-2x": airlineIconAY2x,
  "AY-3x": airlineIconAY3x,
  "AY-4x": airlineIconAY4x,
  "AZ-2x": airlineIconAZ2x,
  "AZ-3x": airlineIconAZ3x,
  "AZ-4x": airlineIconAZ4x,
  "B2-2x": airlineIconB22x,
  "B2-3x": airlineIconB23x,
  "B2-4x": airlineIconB24x,
  "B6-2x": airlineIconB62x,
  "B6-3x": airlineIconB63x,
  "B6-4x": airlineIconB64x,
  "BA-2x": airlineIconBA2x,
  "BA-3x": airlineIconBA3x,
  "BA-4x": airlineIconBA4x,
  "BB-2x": airlineIconBB2x,
  "BB-3x": airlineIconBB3x,
  "BB-4x": airlineIconBB4x,
  "BE-2x": airlineIconBE2x,
  "BE-3x": airlineIconBE3x,
  "BE-4x": airlineIconBE4x,
  "BF-2x": airlineIconBF2x,
  "BF-3x": airlineIconBF3x,
  "BF-4x": airlineIconBF4x,
  "BI-2x": airlineIconBI2x,
  "BI-3x": airlineIconBI3x,
  "BI-4x": airlineIconBI4x,
  "BL-2x": airlineIconBL2x,
  "BL-3x": airlineIconBL3x,
  "BL-4x": airlineIconBL4x,
  "BM-2x": airlineIconBM2x,
  "BM-3x": airlineIconBM3x,
  "BM-4x": airlineIconBM4x,
  "BP-2x": airlineIconBP2x,
  "BP-3x": airlineIconBP3x,
  "BP-4x": airlineIconBP4x,
  "BR-2x": airlineIconBR2x,
  "BR-3x": airlineIconBR3x,
  "BR-4x": airlineIconBR4x,
  "BT-2x": airlineIconBT2x,
  "BT-3x": airlineIconBT3x,
  "BT-4x": airlineIconBT4x,
  "BW-2x": airlineIconBW2x,
  "BW-3x": airlineIconBW3x,
  "BW-4x": airlineIconBW4x,
  "BX-2x": airlineIconBX2x,
  "BX-3x": airlineIconBX3x,
  "BX-4x": airlineIconBX4x,
  "CA-2x": airlineIconCA2x,
  "CA-3x": airlineIconCA3x,
  "CA-4x": airlineIconCA4x,
  "CI-2x": airlineIconCI2x,
  "CI-3x": airlineIconCI3x,
  "CI-4x": airlineIconCI4x,
  "CM-2x": airlineIconCM2x,
  "CM-3x": airlineIconCM3x,
  "CM-4x": airlineIconCM4x,
  "CX-2x": airlineIconCX2x,
  "CX-3x": airlineIconCX3x,
  "CX-4x": airlineIconCX4x,
  "CZ-2x": airlineIconCZ2x,
  "CZ-3x": airlineIconCZ3x,
  "CZ-4x": airlineIconCZ4x,
  "D8-2x": airlineIconD82x,
  "D8-3x": airlineIconD83x,
  "D8-4x": airlineIconD84x,
  "DE-2x": airlineIconDE2x,
  "DE-3x": airlineIconDE3x,
  "DE-4x": airlineIconDE4x,
  "DI-2x": airlineIconDI2x,
  "DI-3x": airlineIconDI3x,
  "DL-2x": airlineIconDL2x,
  "DL-3x": airlineIconDL3x,
  "DL-4x": airlineIconDL4x,
  "DN-2x": airlineIconDN2x,
  "DN-3x": airlineIconDN3x,
  "DT-2x": airlineIconDT2x,
  "DT-3x": airlineIconDT3x,
  "DT-4x": airlineIconDT4x,
  "DY-2x": airlineIconDY2x,
  "DY-3x": airlineIconDY3x,
  "DY-4x": airlineIconDY4x,
  "EB-2x": airlineIconEB2x,
  "EB-3x": airlineIconEB3x,
  "EB-4x": airlineIconEB4x,
  "EI-2x": airlineIconEI2x,
  "EI-3x": airlineIconEI3x,
  "EI-4x": airlineIconEI4x,
  "EK-2x": airlineIconEK2x,
  "EK-3x": airlineIconEK3x,
  "EK-4x": airlineIconEK4x,
  "EQ-2x": airlineIconEQ2x,
  "EQ-3x": airlineIconEQ3x,
  "EQ-4x": airlineIconEQ4x,
  "ET-2x": airlineIconET2x,
  "ET-3x": airlineIconET3x,
  "ET-4x": airlineIconET4x,
  "EW-2x": airlineIconEW2x,
  "EW-3x": airlineIconEW3x,
  "EW-4x": airlineIconEW4x,
  "EY-2x": airlineIconEY2x,
  "EY-3x": airlineIconEY3x,
  "EY-4x": airlineIconEY4x,
  "F7-2x": airlineIconF72x,
  "F7-3x": airlineIconF73x,
  "F7-4x": airlineIconF74x,
  "F9-2x": airlineIconF92x,
  "F9-3x": airlineIconF93x,
  "F9-4x": airlineIconF94x,
  "FB-2x": airlineIconFB2x,
  "FB-3x": airlineIconFB3x,
  "FB-4x": airlineIconFB4x,
  "FI-2x": airlineIconFI2x,
  "FI-3x": airlineIconFI3x,
  "FI-4x": airlineIconFI4x,
  "FJ-2x": airlineIconFJ2x,
  "FJ-3x": airlineIconFJ3x,
  "FJ-4x": airlineIconFJ4x,
  "FR-2x": airlineIconFR2x,
  "FR-3x": airlineIconFR3x,
  "FR-4x": airlineIconFR4x,
  "FS-2x": airlineIconFS2x,
  "FS-3x": airlineIconFS3x,
  "FS-4x": airlineIconFS4x,
  "G3-2x": airlineIconG32x,
  "G3-3x": airlineIconG33x,
  "G3-4x": airlineIconG34x,
  "GA-2x": airlineIconGA2x,
  "GA-3x": airlineIconGA3x,
  "GA-4x": airlineIconGA4x,
  "GE-2x": airlineIconGE2x,
  "GE-3x": airlineIconGE3x,
  "GE-4x": airlineIconGE4x,
  "GF-2x": airlineIconGF2x,
  "GF-3x": airlineIconGF3x,
  "GF-4x": airlineIconGF4x,
  "GK-2x": airlineIconGK2x,
  "GK-3x": airlineIconGK3x,
  "GK-4x": airlineIconGK4x,
  "H1-2x": airlineIconH12x,
  "H1-3x": airlineIconH13x,
  "H1-4x": airlineIconH14x,
  "H2-2x": airlineIconH22x,
  "H2-3x": airlineIconH23x,
  "H2-4x": airlineIconH24x,
  "HA-2x": airlineIconHA2x,
  "HA-3x": airlineIconHA3x,
  "HA-4x": airlineIconHA4x,
  "HM-2x": airlineIconHM2x,
  "HM-3x": airlineIconHM3x,
  "HM-4x": airlineIconHM4x,
  "HU-2x": airlineIconHU2x,
  "HU-3x": airlineIconHU3x,
  "HU-4x": airlineIconHU4x,
  "HX-2x": airlineIconHX2x,
  "HX-3x": airlineIconHX3x,
  "HX-4x": airlineIconHX4x,
  "HY-2x": airlineIconHY2x,
  "HY-3x": airlineIconHY3x,
  "HY-4x": airlineIconHY4x,
  "IB-2x": airlineIconIB2x,
  "IB-3x": airlineIconIB3x,
  "IB-4x": airlineIconIB4x,
  "IG-2x": airlineIconIG2x,
  "IG-3x": airlineIconIG3x,
  "IG-4x": airlineIconIG4x,
  "IZ-2x": airlineIconIZ2x,
  "IZ-3x": airlineIconIZ3x,
  "IZ-4x": airlineIconIZ4x,
  "J2-2x": airlineIconJ22x,
  "J2-3x": airlineIconJ23x,
  "J2-4x": airlineIconJ24x,
  "JJ-2x": airlineIconJJ2x,
  "JJ-3x": airlineIconJJ3x,
  "JJ-4x": airlineIconJJ4x,
  "JL-2x": airlineIconJL2x,
  "JL-3x": airlineIconJL3x,
  "JL-4x": airlineIconJL4x,
  "JP-2x": airlineIconJP2x,
  "JP-3x": airlineIconJP3x,
  "JP-4x": airlineIconJP4x,
  "JQ-2x": airlineIconJQ2x,
  "JQ-3x": airlineIconJQ3x,
  "JQ-4x": airlineIconJQ4x,
  "JU-2x": airlineIconJU2x,
  "JU-3x": airlineIconJU3x,
  "JU-4x": airlineIconJU4x,
  "JV-2x": airlineIconJV2x,
  "JV-3x": airlineIconJV3x,
  "JV-4x": airlineIconJV4x,
  "K5-2x": airlineIconK52x,
  "K5-3x": airlineIconK53x,
  "K5-4x": airlineIconK54x,
  "K6-2x": airlineIconK62x,
  "K6-3x": airlineIconK63x,
  "K6-4x": airlineIconK64x,
  "KA-2x": airlineIconKA2x,
  "KA-3x": airlineIconKA3x,
  "KA-4x": airlineIconKA4x,
  "KC-2x": airlineIconKC2x,
  "KC-3x": airlineIconKC3x,
  "KC-4x": airlineIconKC4x,
  "KE-2x": airlineIconKE2x,
  "KE-3x": airlineIconKE3x,
  "KE-4x": airlineIconKE4x,
  "KL-2x": airlineIconKL2x,
  "KL-3x": airlineIconKL3x,
  "KL-4x": airlineIconKL4x,
  "KM-2x": airlineIconKM2x,
  "KM-3x": airlineIconKM3x,
  "KM-4x": airlineIconKM4x,
  "KQ-2x": airlineIconKQ2x,
  "KQ-3x": airlineIconKQ3x,
  "KQ-4x": airlineIconKQ4x,
  "KS-2x": airlineIconKS2x,
  "KS-3x": airlineIconKS3x,
  "KS-4x": airlineIconKS4x,
  "KU-2x": airlineIconKU2x,
  "KU-3x": airlineIconKU3x,
  "KU-4x": airlineIconKU4x,
  "KX-2x": airlineIconKX2x,
  "KX-3x": airlineIconKX3x,
  "KX-4x": airlineIconKX4x,
  "LA-2x": airlineIconLA2x,
  "LA-3x": airlineIconLA3x,
  "LA-4x": airlineIconLA4x,
  "LG-2x": airlineIconLG2x,
  "LG-3x": airlineIconLG3x,
  "LG-4x": airlineIconLG4x,
  "LH-2x": airlineIconLH2x,
  "LH-3x": airlineIconLH3x,
  "LH-4x": airlineIconLH4x,
  "LI-2x": airlineIconLI2x,
  "LI-3x": airlineIconLI3x,
  "LI-4x": airlineIconLI4x,
  "LO-2x": airlineIconLO2x,
  "LO-3x": airlineIconLO3x,
  "LO-4x": airlineIconLO4x,
  "LR-2x": airlineIconLR2x,
  "LR-3x": airlineIconLR3x,
  "LR-4x": airlineIconLR4x,
  "LS-2x": airlineIconLS2x,
  "LS-3x": airlineIconLS3x,
  "LS-4x": airlineIconLS4x,
  "LT-2x": airlineIconLT2x,
  "LT-3x": airlineIconLT3x,
  "LT-4x": airlineIconLT4x,
  "LX-2x": airlineIconLX2x,
  "LX-3x": airlineIconLX3x,
  "LX-4x": airlineIconLX4x,
  "LY-2x": airlineIconLY2x,
  "LY-3x": airlineIconLY3x,
  "LY-4x": airlineIconLY4x,
  "MD-2x": airlineIconMD2x,
  "MD-3x": airlineIconMD3x,
  "MD-4x": airlineIconMD4x,
  "ME-2x": airlineIconME2x,
  "ME-3x": airlineIconME3x,
  "ME-4x": airlineIconME4x,
  "MF-2x": airlineIconMF2x,
  "MF-3x": airlineIconMF3x,
  "MF-4x": airlineIconMF4x,
  "MH-2x": airlineIconMH2x,
  "MH-3x": airlineIconMH3x,
  "MH-4x": airlineIconMH4x,
  "MI-2x": airlineIconMI2x,
  "MI-3x": airlineIconMI3x,
  "MI-4x": airlineIconMI4x,
  "MJ-2x": airlineIconMJ2x,
  "MJ-3x": airlineIconMJ3x,
  "MJ-4x": airlineIconMJ4x,
  "MK-2x": airlineIconMK2x,
  "MK-3x": airlineIconMK3x,
  "MK-4x": airlineIconMK4x,
  "ML-2x": airlineIconML2x,
  "ML-3x": airlineIconML3x,
  "ML-4x": airlineIconML4x,
  "MS-2x": airlineIconMS2x,
  "MS-3x": airlineIconMS3x,
  "MS-4x": airlineIconMS4x,
  "MT-2x": airlineIconMT2x,
  "MT-3x": airlineIconMT3x,
  "MT-4x": airlineIconMT4x,
  "MU-2x": airlineIconMU2x,
  "MU-3x": airlineIconMU3x,
  "MU-4x": airlineIconMU4x,
  "MW-2x": airlineIconMW2x,
  "MW-3x": airlineIconMW3x,
  "MW-4x": airlineIconMW4x,
  "MX-2x": airlineIconMX2x,
  "MX-3x": airlineIconMX3x,
  "MX-4x": airlineIconMX4x,
  "NF-2x": airlineIconNF2x,
  "NF-3x": airlineIconNF3x,
  "NF-4x": airlineIconNF4x,
  "NH-2x": airlineIconNH2x,
  "NH-3x": airlineIconNH3x,
  "NH-4x": airlineIconNH4x,
  "NK-2x": airlineIconNK2x,
  "NK-3x": airlineIconNK3x,
  "NK-4x": airlineIconNK4x,
  "NT-2x": airlineIconNT2x,
  "NT-3x": airlineIconNT3x,
  "NT-4x": airlineIconNT4x,
  "NX-2x": airlineIconNX2x,
  "NX-3x": airlineIconNX3x,
  "NX-4x": airlineIconNX4x,
  "NZ-2x": airlineIconNZ2x,
  "NZ-3x": airlineIconNZ3x,
  "NZ-4x": airlineIconNZ4x,
  "O6-2x": airlineIconO62x,
  "O6-3x": airlineIconO63x,
  "O6-4x": airlineIconO64x,
  "OA-2x": airlineIconOA2x,
  "OA-3x": airlineIconOA3x,
  "OA-4x": airlineIconOA4x,
  "OK-2x": airlineIconOK2x,
  "OK-3x": airlineIconOK3x,
  "OK-4x": airlineIconOK4x,
  "OS-2x": airlineIconOS2x,
  "OS-3x": airlineIconOS3x,
  "OS-4x": airlineIconOS4x,
  "OU-2x": airlineIconOU2x,
  "OU-3x": airlineIconOU3x,
  "OU-4x": airlineIconOU4x,
  "OV-2x": airlineIconOV2x,
  "OV-3x": airlineIconOV3x,
  "OV-4x": airlineIconOV4x,
  "OY-2x": airlineIconOY2x,
  "OY-3x": airlineIconOY3x,
  "OY-4x": airlineIconOY4x,
  "OZ-2x": airlineIconOZ2x,
  "OZ-3x": airlineIconOZ3x,
  "OZ-4x": airlineIconOZ4x,
  "P9-2x": airlineIconP92x,
  "P9-3x": airlineIconP93x,
  "P9-4x": airlineIconP94x,
  "PC-2x": airlineIconPC2x,
  "PC-3x": airlineIconPC3x,
  "PC-4x": airlineIconPC4x,
  "PD-2x": airlineIconPD2x,
  "PD-3x": airlineIconPD3x,
  "PD-4x": airlineIconPD4x,
  "PG-2x": airlineIconPG2x,
  "PG-3x": airlineIconPG3x,
  "PG-4x": airlineIconPG4x,
  "PK-2x": airlineIconPK2x,
  "PK-3x": airlineIconPK3x,
  "PK-4x": airlineIconPK4x,
  "PR-2x": airlineIconPR2x,
  "PR-3x": airlineIconPR3x,
  "PR-4x": airlineIconPR4x,
  "PS-2x": airlineIconPS2x,
  "PS-3x": airlineIconPS3x,
  "PS-4x": airlineIconPS4x,
  "PU-2x": airlineIconPU2x,
  "PU-3x": airlineIconPU3x,
  "PU-4x": airlineIconPU4x,
  "PX-2x": airlineIconPX2x,
  "PX-3x": airlineIconPX3x,
  "PX-4x": airlineIconPX4x,
  "PY-2x": airlineIconPY2x,
  "PY-3x": airlineIconPY3x,
  "PY-4x": airlineIconPY4x,
  "PZ-2x": airlineIconPZ2x,
  "PZ-3x": airlineIconPZ3x,
  "PZ-4x": airlineIconPZ4x,
  "QF-2x": airlineIconQF2x,
  "QF-3x": airlineIconQF3x,
  "QF-4x": airlineIconQF4x,
  "QR-2x": airlineIconQR2x,
  "QR-3x": airlineIconQR3x,
  "QR-4x": airlineIconQR4x,
  "QV-2x": airlineIconQV2x,
  "QV-3x": airlineIconQV3x,
  "QV-4x": airlineIconQV4x,
  "R7-2x": airlineIconR72x,
  "R7-3x": airlineIconR73x,
  "R7-4x": airlineIconR74x,
  "RJ-2x": airlineIconRJ2x,
  "RJ-3x": airlineIconRJ3x,
  "RJ-4x": airlineIconRJ4x,
  "RO-2x": airlineIconRO2x,
  "RO-3x": airlineIconRO3x,
  "RO-4x": airlineIconRO4x,
  "S3-2x": airlineIconS32x,
  "S3-3x": airlineIconS33x,
  "S3-4x": airlineIconS34x,
  "S4-2x": airlineIconS42x,
  "S4-3x": airlineIconS43x,
  "S4-4x": airlineIconS44x,
  "S7-2x": airlineIconS72x,
  "S7-3x": airlineIconS73x,
  "S7-4x": airlineIconS74x,
  "SA-2x": airlineIconSA2x,
  "SA-3x": airlineIconSA3x,
  "SA-4x": airlineIconSA4x,
  "SE-2x": airlineIconSE2x,
  "SE-3x": airlineIconSE3x,
  "SE-4x": airlineIconSE4x,
  "SK-2x": airlineIconSK2x,
  "SK-3x": airlineIconSK3x,
  "SK-4x": airlineIconSK4x,
  "SN-2x": airlineIconSN2x,
  "SN-3x": airlineIconSN3x,
  "SN-4x": airlineIconSN4x,
  "SQ-2x": airlineIconSQ2x,
  "SQ-3x": airlineIconSQ3x,
  "SQ-4x": airlineIconSQ4x,
  "SS-2x": airlineIconSS2x,
  "SS-3x": airlineIconSS3x,
  "SS-4x": airlineIconSS4x,
  "ST-2x": airlineIconST2x,
  "ST-3x": airlineIconST3x,
  "ST-4x": airlineIconST4x,
  "SU-2x": airlineIconSU2x,
  "SU-3x": airlineIconSU3x,
  "SU-4x": airlineIconSU4x,
  "SV-2x": airlineIconSV2x,
  "SV-3x": airlineIconSV3x,
  "SV-4x": airlineIconSV4x,
  "SW-2x": airlineIconSW2x,
  "SW-3x": airlineIconSW3x,
  "SW-4x": airlineIconSW4x,
  "SX-2x": airlineIconSX2x,
  "SX-3x": airlineIconSX3x,
  "SX-4x": airlineIconSX4x,
  "SY-2x": airlineIconSY2x,
  "SY-3x": airlineIconSY3x,
  "SY-4x": airlineIconSY4x,
  "TA-2x": airlineIconTA2x,
  "TA-3x": airlineIconTA3x,
  "TA-4x": airlineIconTA4x,
  "TB-2x": airlineIconTB2x,
  "TB-3x": airlineIconTB3x,
  "TB-4x": airlineIconTB4x,
  "TF-2x": airlineIconTF2x,
  "TF-3x": airlineIconTF3x,
  "TF-4x": airlineIconTF4x,
  "TG-2x": airlineIconTG2x,
  "TG-3x": airlineIconTG3x,
  "TG-4x": airlineIconTG4x,
  "TK-2x": airlineIconTK2x,
  "TK-3x": airlineIconTK3x,
  "TK-4x": airlineIconTK4x,
  "TN-2x": airlineIconTN2x,
  "TN-3x": airlineIconTN3x,
  "TN-4x": airlineIconTN4x,
  "TP-2x": airlineIconTP2x,
  "TP-3x": airlineIconTP3x,
  "TP-4x": airlineIconTP4x,
  "TS-2x": airlineIconTS2x,
  "TS-3x": airlineIconTS3x,
  "TS-4x": airlineIconTS4x,
  "TU-2x": airlineIconTU2x,
  "TU-3x": airlineIconTU3x,
  "TU-4x": airlineIconTU4x,
  "TX-2x": airlineIconTX2x,
  "TX-3x": airlineIconTX3x,
  "TX-4x": airlineIconTX4x,
  "U2-2x": airlineIconU22x,
  "U2-3x": airlineIconU23x,
  "U2-4x": airlineIconU24x,
  "U6-2x": airlineIconU62x,
  "U6-3x": airlineIconU63x,
  "U6-4x": airlineIconU64x,
  "UA-2x": airlineIconUA2x,
  "UA-3x": airlineIconUA3x,
  "UA-4x": airlineIconUA4x,
  "UL-2x": airlineIconUL2x,
  "UL-3x": airlineIconUL3x,
  "UL-4x": airlineIconUL4x,
  "UN-2x": airlineIconUN2x,
  "UN-3x": airlineIconUN3x,
  "UN-4x": airlineIconUN4x,
  "UO-2x": airlineIconUO2x,
  "UO-3x": airlineIconUO3x,
  "UO-4x": airlineIconUO4x,
  "UP-2x": airlineIconUP2x,
  "UP-3x": airlineIconUP3x,
  "UP-4x": airlineIconUP4x,
  "US-2x": airlineIconUS2x,
  "US-3x": airlineIconUS3x,
  "US-4x": airlineIconUS4x,
  "UU-2x": airlineIconUU2x,
  "UU-3x": airlineIconUU3x,
  "UU-4x": airlineIconUU4x,
  "UX-2x": airlineIconUX2x,
  "UX-3x": airlineIconUX3x,
  "UX-4x": airlineIconUX4x,
  "V7-2x": airlineIconV72x,
  "V7-3x": airlineIconV73x,
  "V7-4x": airlineIconV74x,
  "VA-2x": airlineIconVA2x,
  "VA-3x": airlineIconVA3x,
  "VA-4x": airlineIconVA4x,
  "VN-2x": airlineIconVN2x,
  "VN-3x": airlineIconVN3x,
  "VN-4x": airlineIconVN4x,
  "VS-2x": airlineIconVS2x,
  "VS-3x": airlineIconVS3x,
  "VS-4x": airlineIconVS4x,
  "VW-2x": airlineIconVW2x,
  "VW-3x": airlineIconVW3x,
  "VW-4x": airlineIconVW4x,
  "VX-2x": airlineIconVX2x,
  "VX-3x": airlineIconVX3x,
  "VX-4x": airlineIconVX4x,
  "VY-2x": airlineIconVY2x,
  "VY-3x": airlineIconVY3x,
  "VY-4x": airlineIconVY4x,
  "W3-2x": airlineIconW32x,
  "W3-3x": airlineIconW33x,
  "W3-4x": airlineIconW34x,
  "W6-2x": airlineIconW62x,
  "W6-3x": airlineIconW63x,
  "W6-4x": airlineIconW64x,
  "W9-2x": airlineIconW92x,
  "W9-3x": airlineIconW93x,
  "W9-4x": airlineIconW94x,
  "WG-2x": airlineIconWG2x,
  "WG-3x": airlineIconWG3x,
  "WG-4x": airlineIconWG4x,
  "WM-2x": airlineIconWM2x,
  "WM-3x": airlineIconWM3x,
  "WM-4x": airlineIconWM4x,
  "WN-2x": airlineIconWN2x,
  "WN-3x": airlineIconWN3x,
  "WN-4x": airlineIconWN4x,
  "WO-2x": airlineIconWO2x,
  "WO-3x": airlineIconWO3x,
  "WO-4x": airlineIconWO4x,
  "WP-2x": airlineIconWP2x,
  "WP-3x": airlineIconWP3x,
  "WP-4x": airlineIconWP4x,
  "WS-2x": airlineIconWS2x,
  "WS-3x": airlineIconWS3x,
  "WS-4x": airlineIconWS4x,
  "WW-2x": airlineIconWW2x,
  "WW-3x": airlineIconWW3x,
  "WW-4x": airlineIconWW4x,
  "WX-2x": airlineIconWX2x,
  "WX-3x": airlineIconWX3x,
  "WX-4x": airlineIconWX4x,
  "WY-2x": airlineIconWY2x,
  "WY-3x": airlineIconWY3x,
  "WY-4x": airlineIconWY4x,
  "XG-2x": airlineIconXG2x,
  "XG-3x": airlineIconXG3x,
  "XG-4x": airlineIconXG4x,
  "XL-2x": airlineIconXL2x,
  "XL-3x": airlineIconXL3x,
  "XL-4x": airlineIconXL4x,
  "XQ-2x": airlineIconXQ2x,
  "XQ-3x": airlineIconXQ3x,
  "XQ-4x": airlineIconXQ4x,
  "YO-2x": airlineIconYO2x,
  "YO-3x": airlineIconYO3x,
  "YO-4x": airlineIconYO4x,
  "Z8-2x": airlineIconZ82x,
  "Z8-3x": airlineIconZ83x,
  "Z8-4x": airlineIconZ84x,
  "ZI-2x": airlineIconZI2x,
  "ZI-3x": airlineIconZI3x,
  "ZI-4x": airlineIconZI4x,
  "ZK-2x": airlineIconZK2x,
  "ZK-3x": airlineIconZK3x,
  "ZK-4x": airlineIconZK4x,
  "ZL-2x": airlineIconZL2x,
  "ZL-3x": airlineIconZL3x,
  "ZL-4x": airlineIconZL4x,
};

const iconSizeMap = {
  small: "2x",
  medium: "3x",
  large: "4x",
};

export type AirlineIconSize = keyof typeof iconSizeMap;
export { iconNameToSourceMap, iconSizeMap };
