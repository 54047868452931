import { axiosInstance } from "@hopper-b2b/api";
import {
  type GetRotatingBannersResponse,
  GetRotatingBannersResponseResponseEnum,
  adsApi,
} from "@b2bportal/ads-api";

export const fetchRotatingBanners = async (
  abortSignal: AbortSignal
): Promise<GetRotatingBannersResponse> => {
  return new Promise((resolve, reject) => {
    const body = {};

    try {
      adsApi(axiosInstance)
        .apiV0AdsRotatingBannersPost(body, { signal: abortSignal })
        .then((res) => {
          const response = res.data;

          switch (response.Response) {
            case GetRotatingBannersResponseResponseEnum.Success:
              resolve(response.value);
              break;
            case GetRotatingBannersResponseResponseEnum.Failure:
              reject(
                new Error(
                  "Server returned an error: " + response.errors.join(", ")
                )
              );
              break;
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      reject(e);
    }
  });
};
