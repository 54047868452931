import { isHopperBrand } from "./brand";

const HOPPER_APP_WEB_VIEW_USER_AGENT = "hopperapp";
const HOPPER_APP_WEB_MOBILE_APP_VERSION_COOKIE_KEY = "mobile_app_version";
const HOPPER_APP_WEB_MOBILE_APP_BUILD_NUMBER_COOKIE_KEY =
  "mobile_app_build_number";
const HOPPER_APP_WEB_MOBILE_APP_DEVICE_ID_COOKIE_KEY = "mobile_app_device_id";
const HOPPER_APP_WEB_MOBILE_APP_OS_COOKIE_KEY = "mobile_app_os";
const ANDROID_PLATFORM = "android";
const IOS_PLATFORM = "ios";

/**
 * @returns {boolean} true if the user agent is the Hopper App web view and the website is Hopper,
 *  meaning that the page is loaded in a web view within the iOS or Android Hopper App.
 */
export const isHopperAppWebView = (): boolean =>
  isHopperBrand() &&
  typeof window !== "undefined" &&
  navigator.userAgent.toLowerCase().includes(HOPPER_APP_WEB_VIEW_USER_AGENT);

export const getMobileAppVersion = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_VERSION_COOKIE_KEY);
export const getMobileAppBuildNumber = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_BUILD_NUMBER_COOKIE_KEY);
export const getMobileAppDeviceId = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_DEVICE_ID_COOKIE_KEY);
export const getMobileAppOs = (): string | undefined =>
  readCookie(HOPPER_APP_WEB_MOBILE_APP_OS_COOKIE_KEY);
export const getMobileAppPlatform = (): string | undefined => {
  switch (getMobileAppOs()?.toLowerCase()) {
    case ANDROID_PLATFORM:
      return "m1";
    case IOS_PLATFORM:
      return "c1";
    // Invalid OS, return web platform as default
    default:
      return "w1";
  }
};

const readCookie = (key: string) => {
  let cookieValue;
  try {
    cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(key))
      ?.split("=")[1];
  } catch (e) {
    cookieValue = "";
  }

  return cookieValue;
};
