import { useIsServerSideRendering } from "@hopper-b2b/utilities";
import { Box, BoxProps } from "@mui/material";
import NextImage, { StaticImageData } from "next/image";
import { ImgHTMLAttributes } from "react";

//These components are used to handle images differently depending on whether the app is running on the server or the client.

export const Image = (
  props: BoxProps & ImgHTMLAttributes<HTMLImageElement>
) => {
  const isServerSideRendering = useIsServerSideRendering();

  return (
    <Box component={isServerSideRendering ? NextImage : "img"} {...props} />
  );
};

export const useImageSrc = (src: string | StaticImageData) => {
  const isServerSideRendering = useIsServerSideRendering();

  return isServerSideRendering
    ? (src as unknown as StaticImageData)?.src
    : (src as string);
};
