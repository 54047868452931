import { adsApi } from "@hopper-b2b/ads-api";
import { flashSaleApi } from "@hopper-b2b/flashsale";
import {
  configureStore,
  Middleware,
  ReducersMapObject,
} from "@reduxjs/toolkit";

export interface ConfigureStoreOptions {
  reducers?: ReducersMapObject;
  middlewares?: Middleware[];
}

export const createStore = ({
  reducers,
  middlewares = [],
}: ConfigureStoreOptions) =>
  configureStore({
    reducer: {
      [flashSaleApi.reducerPath]: flashSaleApi.reducer,
      [adsApi.reducerPath]: adsApi.reducer,
      ...reducers,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        flashSaleApi.middleware,
        adsApi.middleware,
        ...middlewares,
      ]),
  });
