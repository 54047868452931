"use client";

import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import { createContext, PropsWithChildren, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export type ISSRContextProps = {
  isServerSideRendering: boolean;
};

export interface ISSRContextProvider {
  locale: string;
  ssrContext: ISSRContextProps;
}

export const defaultSSRContext: ISSRContextProps = {
  isServerSideRendering: false,
};

export const SSRContext = createContext<ISSRContextProps>(defaultSSRContext);

const useSSRContext = () => {
  const ctx = useContext(SSRContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SSRContextProvider`);
  return { ...ctx };
};

/**
 * Hook to determine if the code is running on the server side.
 * @returns boolean - true if the code is running on the server side, false otherwise
 */
export const useIsServerSideRendering = () => {
  const { isServerSideRendering } = useSSRContext();
  return isServerSideRendering;
};

const WithAppRouterCacheProvider = ({
  locale,
  children,
}: PropsWithChildren<{ locale: string }>) => {
  const isServerSideRendering = useIsServerSideRendering();

  return isServerSideRendering ? (
    <AppRouterCacheProvider options={{ key: "css" }}>
      {children}
    </AppRouterCacheProvider>
  ) : (
    <HelmetProvider>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export const SSRContextProvider = ({
  children,
  locale,
  ssrContext,
}: PropsWithChildren<ISSRContextProvider>) => {
  return (
    <SSRContext.Provider value={ssrContext}>
      <WithAppRouterCacheProvider locale={locale}>
        {children}
      </WithAppRouterCacheProvider>
    </SSRContext.Provider>
  );
};
