import {
  ClaimOfferRequest,
  ClaimOfferResponse,
  hopperFlashSaleApi,
} from "@b2bportal/hopper-flash-sale-api";
import { axiosInstance } from "@hopper-b2b/api";

export const claimOffer = async (
  request: ClaimOfferRequest
): Promise<ClaimOfferResponse> => {
  return new Promise((resolve, reject) => {
    try {
      hopperFlashSaleApi(axiosInstance)
        .apiV0FlashSaleOffersClaimPost(request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};
