import { AsYouTypeFormatter, PhoneNumberUtil } from "google-libphonenumber";

import { uniquePhoneCodesWithCountryCode } from "./countryPhoneCode";

// full validation of a phone number for a country using length and prefix information
// used before creating a session as this should catch invalid number such as 444-123-1234
// and we can display better FE validation message to users.
export const isPhoneNumberValidForCountry = (
  phone: string,
  countryCode: string
) => {
  const regionCode = uniquePhoneCodesWithCountryCode.find(
    (code) => code.areaCode === countryCode.substring(1)
  ).countryCode;

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedPhone = phoneUtil.parseAndKeepRawInput(phone, regionCode);
    return phoneUtil.isValidNumber(parsedPhone);
  } catch (e) {
    return false;
  }
};

type ParsedPhoneNumber = {
  nationalNumber: string;
  regionCode: string;
  countryCode: string;
};
export const parsePhoneNumber = (
  phone?: string,
  lenient?: boolean
): ParsedPhoneNumber => {
  if (!phone) {
    return {
      nationalNumber: "",
      countryCode: "",
      regionCode: "",
    };
  }

  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);
    const regionCode = phoneUtil.getRegionCodeForNumber(parsedPhone);
    return {
      nationalNumber: `${parsedPhone.getNationalNumber()}`,
      countryCode: `${parsedPhone.getCountryCode()}`,
      regionCode,
    };
  } catch (e) {
    // Note: parsing errors should not be exceptions ... poor design choice
    if (lenient) {
      const matches = uniquePhoneCodesWithCountryCode.filter(
        ({ areaCode }) =>
          phone.startsWith(areaCode) || phone.startsWith(`+${areaCode}`)
      );
      if (matches.length > 0) {
        const candidate = matches[matches.length - 1];
        const regionCode = candidate.countryCode;
        const countryCode = candidate.areaCode;
        const nationalNumber = phone.replace(`+${countryCode}`, "");
        return {
          nationalNumber,
          regionCode,
          countryCode,
        };
      } else {
        return {
          nationalNumber: "",
          regionCode: "",
          countryCode: "",
        };
      }
    }
  }
};

export const formatPhoneNumber = (phone: string, countryCode: string) => {
  // Remove non-digit characters
  const cleanedPhone = phone.replace(/\D/g, "");

  // Add in length constraint if needed. This does not comply with people inputting local numbers
  // eg: GB - 024 7XXX XXXX
  // const trimmedInput = cleanedPhone.substring(0, 10);

  // Get country code without the "+"
  const trimmedCountryCode = countryCode.substring(1);
  const countryCodeNumber = parseInt(trimmedCountryCode, 10);

  // Get Region code from country code
  const phoneUtil = PhoneNumberUtil.getInstance();
  const regionCode = phoneUtil.getRegionCodeForCountryCode(countryCodeNumber);

  // Get formatted phone number string
  const formatter = new AsYouTypeFormatter(regionCode);
  // AsYouTypeFormatter does not expose an way to get the formatted string after adding values in
  // On adding a digit, it returns the formatted string, so the last index will be used
  const formattedString = cleanedPhone
    .split("")
    .map((char) => formatter.inputDigit(char))
    .at(-1);

  return formattedString || "";
};
