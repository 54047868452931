"use client";

import { putFetchActiveExperiments } from "@hopper-b2b/api";
import {
  ExperimentState,
  ExperimentsType,
  IApiConfig,
  TrackingPropertiesType,
} from "@hopper-b2b/types";
import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";

export const HIDE_CC = "ctr-marketplace-hidecreditcard";

export type UserExperiments = ExperimentState & {
  initialized: boolean;
};

const defaultInitState: UserExperiments = {
  initialized: false,
  experiments: {},
  trackingProperties: undefined,
};

export const ExperimentsContext =
  createContext<UserExperiments>(defaultInitState);

type ExperimentsProviderProps = {
  apiConfig: IApiConfig;
  isLoggedIn?: boolean;
  isServerSideRendering?: boolean;
  initialExperiments?: ExperimentsType;
};

export const ExperimentsProvider: FC<
  ExperimentsProviderProps & PropsWithChildren
> = ({
  apiConfig,
  isLoggedIn = false,
  isServerSideRendering = false,
  initialExperiments,
  children,
}) => {
  const [initialized, setInitialized] = useState(defaultInitState.initialized);
  const [experiments, setExperiments] = useState<ExperimentsType>(
    initialExperiments || defaultInitState.experiments
  );
  const [trackingProperties, setTrackingProperties] =
    useState<TrackingPropertiesType>(defaultInitState.trackingProperties);

  useEffect(() => {
    if (!isLoggedIn) return;

    setInitialized(false);

    putFetchActiveExperiments(apiConfig)
      .then((newState: ExperimentState) => {
        setExperiments(
          (currentState: ExperimentsType) =>
            ({
              ...currentState,
              ...newState.experiments,
            } as ExperimentsType)
        );
        setTrackingProperties({
          experiments: Object.keys(newState.experiments).map(
            (key: string) => `${key}_${newState.experiments[key]}`
          ),
        });
      })
      .finally(() => {
        setInitialized(true);
      });
  }, [apiConfig, isLoggedIn]);

  return (
    <ExperimentsContext.Provider
      value={{ initialized, experiments, trackingProperties }}
      children={
        isServerSideRendering || !isLoggedIn || initialized ? children : null
      }
    />
  );
};
