import {
  GetPublishedFlashSaleResponse,
  hopperFlashSaleApi,
} from "@b2bportal/hopper-flash-sale-api";
import { axiosInstance } from "@hopper-b2b/api";

export const fetchPublishedFlashSale =
  async (): Promise<GetPublishedFlashSaleResponse> => {
    return new Promise((resolve, reject) => {
      try {
        hopperFlashSaleApi(axiosInstance)
          .apiV0FlashSalePublishedSaleGet()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      } catch (e) {
        reject(e);
      }
    });
  };
