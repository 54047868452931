import React from "react";

export type ExternalRedirectProps = {
  url: string;
};

export const ExternalRedirect = ({ url }: ExternalRedirectProps) => {
  React.useEffect(() => {
    window.location.href = url;
  }, []);

  return <></>;
};
