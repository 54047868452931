import { GetRotatingBannersResponse, RotatingBanner } from "@b2bportal/ads-api";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { fetchRotatingBanners } from "../api";

export interface IHomePageState {
  rotatingBanners: Array<RotatingBanner>;
}

export const initialState: IHomePageState = {
  rotatingBanners: null,
};

export const adsApi = createApi({
  reducerPath: "adsApi",
  baseQuery: undefined,
  endpoints: (builder) => ({
    fetchRotatingBanners: builder.query<GetRotatingBannersResponse, void>({
      queryFn: async (arg, api, extraOptions) => {
        const data = await fetchRotatingBanners(api.signal);
        return { data };
      },
    }),
  }),
});

export const { useFetchRotatingBannersQuery } = adsApi;
