"use client";

import { ReactNode, ScriptHTMLAttributes } from "react";
import { Helmet, HelmetTags } from "react-helmet-async";
import Script from "next/script";
import { useIsServerSideRendering } from "../context";

export type UseScriptArgs<T> = ScriptHTMLAttributes<T> & {
  onLoad: (event: unknown) => void;
};

export const useScript = ({
  onLoad,
  ...rest
}: UseScriptArgs<unknown>): ReactNode => {
  const isServerSideRendering = useIsServerSideRendering();
  // See: https://github.com/nfl/react-helmet/issues/146#issuecomment-271552211
  const handleScriptInject = ({ scriptTags }: HelmetTags) => {
    if (scriptTags) {
      const scriptTag = scriptTags[0];
      scriptTag.addEventListener("load", onLoad);
    }
  };

  return isServerSideRendering ? (
    <Script src={rest.src} onReady={() => onLoad(null)} />
  ) : (
    <Helmet
      onChangeClientState={(_newState, addedTags) =>
        handleScriptInject(addedTags)
      }
    >
      <script type="text/javascript" async={true} defer={true} {...rest} />
    </Helmet>
  );
};
