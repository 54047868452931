import {
  GetUserSaleContextResponse,
  hopperFlashSaleApi,
} from "@b2bportal/hopper-flash-sale-api";
import { axiosInstance } from "@hopper-b2b/api";

export interface GetUserSaleContextRequest {
  saleId: string;
}

export const fetchUserSaleContext = async ({
  saleId,
}: GetUserSaleContextRequest): Promise<GetUserSaleContextResponse> => {
  return new Promise((resolve, reject) => {
    try {
      hopperFlashSaleApi(axiosInstance)
        .apiV0FlashSaleUserContextGet({
          params: {
            saleId,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};
