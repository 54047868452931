"use client";

import { SendCodeRequestEnum, UserState } from "@b2bportal/auth-api";
import {
  AxiosInterceptors,
  AxiosInterceptorsNext,
  AxiosInterceptorsProps,
  axiosInstance,
  createRequestLog,
  createResponseErrorLog,
  createResponseLog,
} from "@hopper-b2b/api";
import { useExperiment, useExperiments } from "@hopper-b2b/experiments";

import { branding } from "@hopper-b2b/hopper-theming";
import {
  ActionName,
  // getRecaptchaToken,
  recaptchaEndpoints,
} from "@hopper-b2b/hopper-utils";
import { getCurrency, getRootLang, useI18nContext } from "@hopper-b2b/i18n";
import {
  useIsExternalHost,
  useIsServerSideRendering,
  useUserSource,
  defaultCurrency as maybeDefaultCurrency,
  isAtHotelBrand,
} from "@hopper-b2b/utilities";
import axios, { InternalAxiosRequestConfig } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthProvider } from "../../context";

export interface IAxiosInterceptors {
  version?: string;
  recaptchaActionKey?: string;
  children?: React.ReactNode;
  hopperSessionToken?: string;
  errorAction?: AxiosInterceptorsProps["errorAction"];
  currency?: string;
}

const TENANT = "hopper-app";

async function waitUntil(
  condition: () => boolean,
  timeout = 5000 /* 5 seconds */,
  retryAfter = 50 /* 50 milliseconds */
) {
  if (condition()) return;
  const start = Date.now();
  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(() => {
      if (condition()) {
        clearInterval(interval);
        resolve();
      } else if (Date.now() - start >= timeout) {
        clearInterval(interval);
        reject();
      }
    }, retryAfter);
  });
}

// Wait for recaptcha cookie existance periodically
// Returns a promise
const waitForRecaptcha = (() => {
  let waitPromise = null;

  return async function (
    options = {
      timeout: 5000 /* 5 seconds */,
      retryAfter: 50 /* milliseconds */,
    }
  ) {
    if (import.meta.env.DEV) return;
    if (!waitPromise)
      waitPromise = waitUntil(
        () => document.cookie.includes("recaptcha-ca-t"),
        options.timeout,
        options.retryAfter
      ).catch((e) => {
        console.error("Recaptcha failure", e);
      });
    return waitPromise;
  };
})();

const AxiosInterceptorWrapper = ({
  children,
  hopperSessionToken,
  version,
  recaptchaActionKey,
  errorAction,
}: IAxiosInterceptors) => {
  const { language } = useI18nContext();
  const userInfoContext = useAuthProvider();
  const shouldDefaultCurrency =
    useExperiment("WebHotelPreferredCurrencyDefaulting") && isAtHotelBrand();
  const defaultCurrency = maybeDefaultCurrency(
    shouldDefaultCurrency,
    userInfoContext?.state?.sessionInfo?.userInfo?.defaultCurrency
  );
  const { trackingProperties } = useExperiments();
  const userSource = useUserSource();
  const isServerSideRendering = useIsServerSideRendering();
  const { requestConfig: reqConfigOverride } = useIsExternalHost();
  const [innerWidth, setInnerWidth] = useState(0);
  const [currencyParam, setCurrencyParam] = useState<string | null>(null);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
    const param = new URLSearchParams(window.location.search)
      .get("currency")
      ?.toUpperCase();
    if (param) {
      try {
        if (Intl.supportedValuesOf("currency").includes(param)) {
          setCurrencyParam(param);
        }
      } catch {
        setCurrencyParam(null);
      }
    }
    window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setInnerWidth(window.innerWidth)
      );
  }, []);

  /**
   * This is used by the meta search deep linking flow
   * where the user may come from an external site with currency previously set.
   */

  const currency = useMemo(
    () =>
      currencyParam ||
      getCurrency(defaultCurrency) ||
      branding[language]?.currency.code ||
      "USD",
    [language, currencyParam]
  );

  const headers = useMemo(
    () => ({
      "Accept-Language": `${language}, ${getRootLang(language)}`,
      "X-Sec-CH-Viewport-Width": innerWidth,
      "X-Accept-Currency": currency,
    }),
    [language, innerWidth, currency]
  );

  // Using Google reCaptcha tokens on a few key endpoints
  const handleReq = useCallback(
    async (req: InternalAxiosRequestConfig) => {
      if (recaptchaActionKey && recaptchaEndpoints.has(req.url)) {
        try {
          let actionName = recaptchaEndpoints.get(req.url);
          if (actionName === ActionName.SendCode) {
            switch (req.data?.SendCodeRequest) {
              case SendCodeRequestEnum.SendCodeSms:
                actionName = ActionName.SendSMS;
                break;
              case SendCodeRequestEnum.SendCodeEmail:
                actionName = ActionName.SendEmail;
                break;
            }
          }
          // const token = await getRecaptchaToken(recaptchaActionKey, actionName);
          // req.headers["X-Recaptcha-Token"] = token;
        } catch (e) {
          console.error("Error retrieving reCaptcha token:", e);
        }
      }
      await waitForRecaptcha();
      return req;
    },
    [recaptchaActionKey]
  );

  useEffect(() => {
    const requestInterceptor = {
      instance: axiosInstance.interceptors.request.use(handleReq),
      global: axios.interceptors.request.use(handleReq),
    };

    //runs when component unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor.instance);
      axios.interceptors.request.eject(requestInterceptor.global);
    };
  }, [handleReq]);

  if (hopperSessionToken) headers["Hopper-Session"] = hopperSessionToken;

  return isServerSideRendering ? (
    <AxiosInterceptorsNext
      experimentTrackingProperties={trackingProperties}
      userSource={userSource}
      tenant={TENANT}
      currency={currency}
      isAgentPortal={!!userInfoContext?.state?.sessionInfo?.delegatedTo}
      isSignedIn={userInfoContext?.state?.sessionInfo?.userScope?.isSignedIn}
      isGuestUser={
        userInfoContext?.state?.sessionInfo?.userInfo?.userState ===
        UserState.Guest
      }
      delegatedTo={userInfoContext?.state?.sessionInfo?.delegatedTo}
      requestHeaders={headers}
      version={version}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
      errorAction={errorAction}
    >
      {children}
    </AxiosInterceptorsNext>
  ) : (
    <AxiosInterceptors
      experimentTrackingProperties={trackingProperties}
      userSource={userSource}
      tenant={TENANT}
      currency={currency}
      isAgentPortal={!!userInfoContext?.state?.sessionInfo?.delegatedTo}
      isSignedIn={userInfoContext?.state?.sessionInfo?.userScope?.isSignedIn}
      isGuestUser={
        userInfoContext?.state?.sessionInfo?.userInfo?.userState ===
        UserState.Guest
      }
      delegatedTo={userInfoContext?.state?.sessionInfo?.delegatedTo}
      requestConfig={reqConfigOverride}
      requestHeaders={headers}
      version={version}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
      errorAction={errorAction}
    >
      {children}
    </AxiosInterceptors>
  );
};

export default AxiosInterceptorWrapper;
